export default [
    'ar',
    'da',
    'de',
    'en',
    'es',
    'fi',
    'fr',
    'he',
    'it',
    'ja',
    'ko',
    'ms',
    'nb',
    'nl',
    'pl',
    'pt',
    'ru',
    'sv',
    'th',
    'tr',
    'zh-hans',
    'zh-hant',
];
