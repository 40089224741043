export enum OrderType {
    Z1A,
    Z1B,
    Z1C,
    Z1D,
    Z1E,
    Z1F,
    Z1H,
    Z2A,
    Z2B,
    Z3A,
    Z4E,
    Z4F,
    ININVENTORY,
}