import { Icon, TooltipHost } from 'office-ui-fabric-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Styles from './ClaimDetails.module.scss';
import URLS from './../../constants/urls';


const ClaimDetails = (props: any) => {
    const { t } = useTranslation();
    const claimWarrantyInfo = props.claims;
    const getToolTip = () => {
        return (<span>{t('tooltip_message')} <a href={URLS.warranty_and_protection}>{t('terms_and_conditions')}</a></span>);
    };
    const claimList = (<div className={Styles.claimSec}>
        <div className={Styles.planDetail}>
            {
                claimWarrantyInfo && claimWarrantyInfo.map((claim: any, i: number) => {
                    return (
                        <div className={Styles.plan} key={i}>
                            <div className={`${Styles.fontSize} ${Styles.typeCodeWidth}`}>{claim.warrantyTypeCode}</div>
                            <div className={`${Styles.fontSizeSmall} ${Styles.fontBold} ${Styles.descCodeWidth}`}>
                                {claim.warrantyPlanName}
                            </div>
                            <div className={`${Styles.fontSizeSmall} ${Styles.planWidth}`}>
                                <div>
                                    <span>{t('max_claim_count')} </span>
                                    <span className={Styles.fontBold}> {claim.inWarrantyMaxClaimCount}</span>
                                </div>
                            </div>
                            <div className={`${Styles.fontSizeSmall} ${Styles.planWidth}`}>
                                <div>
                                    <span> {t('avail_claim_count')}</span>
                                    <span className={Styles.fontBold}> {claim.inwarrantyClaimsLeft}</span>
                                    <span>
                                        <TooltipHost
                                            tooltipProps={{
                                                calloutProps: {
                                                    styles: {
                                                        root: { borderRadius: '6px', boxShadow: '0px 1px 10px 3px #777' },
                                                        beak: { background: '#FFFFFF' },
                                                        beakCurtain: { background: '#FFFFFF', borderRadius: '4px' },
                                                    },
                                                },
                                                styles: {
                                                    content: { fontSize: '14px', padding: '0px 5px', color: '#000000' },
                                                },
                                            }}
                                            content={getToolTip()}
                                            id="Tooltip">
                                            <Icon
                                                className={Styles.infoIcon}
                                                iconName="InfoSolid" />
                                        </TooltipHost>
                                    </span>
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    </div>);
    return (<section data-testid="claimDetails" className={Styles.claims}>
        {claimList}
    </section>
    );
};

export default ClaimDetails;