import i18next from 'i18next';

export default function getColumnHeaders(t: i18next.TFunction): any {
    return [
        {
            fieldName: 'soldToCustomerNbr',
            name: t('sold_to_column'),
            maxWidth: 200,
            minWidth: 100,
            width: 150,
            visible: true,
        },
        {
            fieldName: 'shipToCustomerNbr',
            name: t('ship_to_column'),
            maxWidth: 200,
            minWidth: 100,
            width: 150,
            visible: true,
        },
        {
            fieldName: 'email',
            name: t('partner_email'),
            maxWidth: 200,
            minWidth: 200,
            width: 200,
            visible: true,
        },
        {
            fieldName: 'customerName',
            name: t('name'),
            maxWidth: 200,
            minWidth: 200,
            width: 200,
            visible: true,
        },
        {
            fieldName: 'addressLineOneText',
            name: t('address'),
            maxWidth: 200,
            minWidth: 200,
            width: 200,
            visible: true,
        },
        {
            fieldName: 'addressLineTwoText',
            name: t('Address Line 2'),
            maxWidth: 200,
            minWidth: 100,
            width: 150,
            visible: false,
        },
        {
            fieldName: 'addressLineThreeText',
            name: t('Address Line 3'),
            maxWidth: 200,
            minWidth: 100,
            width: 150,
            visible: false,
        },
        {
            fieldName: 'cityName',
            name: t('city'),
            minWidth: 100,
            maxWidth: 100,
            width: 100,
            visible: true,
        },
        {
            fieldName: 'stateProvinceCode',
            name: t('state'),
            minWidth: 100,
            maxWidth: 100,
            width: 100,
            visible: true,
        },
        {
            fieldName: 'stateProvinceName',
            name: t('state'),
            minWidth: 100,
            maxWidth: 100,
            width: 100,
            visible: false,
        },
        {
            fieldName: 'countryCode',
            name: t('country'),
            minWidth: 100,
            maxWidth: 100,
            width: 100,
            visible: true,
        },
        {
            fieldName: 'countryName',
            name: t('country'),
            minWidth: 40,
            maxWidth: 60,
            width: 50,
            visible: false,
        },
        {
            fieldName: 'postalCode',
            name: t('postal_code'),
            minWidth: 110,
            maxWidth: 110,
            width: 110,
            visible: true,
        },
        {
            fieldName: 'edit',
            name: t('edit'),
            minWidth: 100,
            maxWidth: 100,
            width: 100,
            visible: true,
        },
        {
            fieldName: 'isActive',
            name: t('status'),
            minWidth: 150,
            maxWidth: 150,
            width: 150,
            visible: true,
        },
        {
            fieldName: 'isDummy',
            name: t('is_dummy'),
            minWidth: 100,
            maxWidth: 100,
            width: 100,
            visible: true,
        },
    ];
}
