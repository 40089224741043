import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
    IDropdownOption,
    IChoiceGroupOption,
    PrimaryButton,
    Spinner,
    SpinnerSize,
} from 'office-ui-fabric-react';

import { logPageAction } from './../../lib/ApplicationInsights';
import {
    validateProvince,
    validatePostalCode,
    validationByPattern,
    validationPatterns,
} from './../../helpers/validation';

import { selectedCountryInfoSelector } from './../../store/countries';
import { provincesSelector } from './../../store/provinces';
import { LoadingState } from './../../store/actionStatus';
import {
    validatedAddressSelector,
    GET_ADDRESS_VALIDATION_DATA,
    getAddressValidationData,
} from './../../store/address';

import { countryListSelector, statusSelector } from './../../views/createOrder/Address/selector';
import { ProvinceType } from './../../views/createOrder/Address/Address';

import AddressVerification from './AddressVerification';
import AddressFields from './AddressField';

import Styles from './styles.module.scss';
import { zipCodeSelector } from './../../store/orderCreation/selectors';

interface PropTypes {
    readonly: boolean;
    isWalkInCenter: boolean;
    onContinue: (addressInputFromFields: AddressFieldType) => void;
    defaultAddress?: AddressFieldType;
    isEnterpriseCustomer: boolean;
}

interface ValidationTypes {
    email?: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    address1: string;
    city: string;
    country: string;
}

interface AddressFieldType {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    language: string;
    address1: string;
    address2: string;
    city: string;
    postalCode: string;
    country: string;
    stateOrProvince: string;
    vatId: string;
    customerPO: string;
    validation: ValidationTypes;
}

const INITIAL_STATE = {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    language: '',
    address1: '',
    address2: '',
    city: '',
    postalCode: '',
    country: '',
    stateOrProvince: '',
    vatId: '',
    customerPO: '',
    validation: {
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        address1: '',
        city: '',
        country: '',
    },
};

export default function AddressManager(props: PropTypes) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = React.useState(false);
    const [selectedAddressIndex, setSelectedAddressIndex] = React.useState('');
    const [isSubmitting, setSubmitting] = React.useState(false);

    const countryInfo = useSelector(state =>
        selectedCountryInfoSelector(state)
    );
    
    const addressValidationLoadingState = useSelector(state =>
        statusSelector(state)(GET_ADDRESS_VALIDATION_DATA)
    );

    const countries = useSelector((state: any) =>
        countryListSelector(state)
    );
    
    const country = countryInfo && countryInfo.countryCode;

    const postalCode = useSelector(state => zipCodeSelector(state));

    const isDisabled =
        isSubmitting &&
        addressValidationLoadingState.status === LoadingState.started;

    const isError =
        addressValidationLoadingState.status === LoadingState.rejected;

    const [addressInputFromFields, setAddressInputFromFields] = React.useState<
        AddressFieldType
    >({ ...INITIAL_STATE, country, postalCode });

    const selectedCountryISOShortCode = React.useMemo(() => {
        const countrySelected = countries.find(
            (country: any) =>
                country.ISOCode === addressInputFromFields.country
        );
        return countrySelected && countrySelected.ISOCountryShortCode;
    }, [addressInputFromFields.country]);

    const provinces = useSelector((state: any) =>
        provincesSelector(state)(
            (selectedCountryISOShortCode || '').toLocaleLowerCase()
        )
    );

    const validatedAddress = useSelector(state =>
            validatedAddressSelector(state)
    );
    
    const languages = t('lists:languages', {
        returnObjects: true,
    }) as any[];

    const languageOptions = React.useMemo(() => {
        return languages.map(
            (lan: { code: string; lcid: string; name: string }) =>
                ({
                    key: lan.code.toLowerCase(),
                    text: lan.name,
                } as IDropdownOption)
        );
    }, [languages]);

    const countryOptions = React.useMemo(() => {
        return countries.sort().map(
            (option: { ISOCode: string | number; ISOName: string }) =>
                ({
                    key: option.ISOCode,
                    text: option.ISOName,
                } as IDropdownOption)
        );
    }, [countries]);

    const provincesOptions = React.useMemo(() => {
        return (
            (provinces ||
            [] as ProvinceType[]).sort().map(
                (option: { ISOCode: string | number; ISOName: string }) =>
                    ({
                        key: option.ISOCode,
                        text: option.ISOName,
                    } as IDropdownOption)
            )
        );
    }, [provinces]);

    function onAddressInputFieldsChange(addressInput: any) {
        setAddressInputFromFields(addressInput);
    }

    function onAddressChoiceChange(
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption
    ): void {
        if (option) {
            setSelectedAddressIndex(option.key);
        }
    }

    function validateEmail(value: any) {
        const { email } = validationPatterns;

        if (!value) {
            return t('required_message', { fieldName: t('email') });
        }
        if (!validationByPattern(email, value)) {
            return t('invalid_email_address');
        }
        return '';
    }

    function validatePhoneNumber(value?: string) {
        const { phoneNumber } = validationPatterns;
        if (!value) {
            return t('required_message', { fieldName: t('phone_number') });
        }

        if (!validationByPattern(phoneNumber, value)) {
            return t('invalid_phone_number');
        }

        return '';
    }

    function onSaveAddressChoicePopupClicked() {
        const indexChosen: number = parseInt(selectedAddressIndex, 10);

        if (indexChosen > -1 && addressValidationLoadingState.status === LoadingState.success) {
            const { addressMatches } = validatedAddress;
            if (addressMatches) {

                const validatedAddress = addressMatches[indexChosen];
                setAddressInputFromFields({
                    ...addressInputFromFields,
                    address1: validatedAddress.address1,
                    address2: validatedAddress.address2,
                    city: validatedAddress.city,
                    postalCode: validatedAddress.postalCode,
                    stateOrProvince: validatedAddress.stateOrProvince,
                    country: validatedAddress.country,
                    vatId: validatedAddress.vatId ? validatedAddress.vatId: addressInputFromFields.vatId,
                    customerPO: validatedAddress.customerPO ? validatedAddress.customerPO: addressInputFromFields.customerPO,
                });
            }
        }
    }

    function onDismissed() {
        setShowModal(false);
        props.onContinue(addressInputFromFields);
    }

    function onCancelAddressChoicePopupClicked() {
        setShowModal(false);
    }
    
    function getAddressChoices(){
        let choices: any[] = [];
        if (addressValidationLoadingState.status === LoadingState.success) {
            const { addressMatches } = validatedAddress;
            if (addressMatches) {
                choices = addressMatches.map(
                    (address: any, index: any) =>
                        ({
                            key: index,
                            text: `${address.address1} ${address.address2 ||
                                ''}, ${address.city} ${address.postalCode ||
                                ''} ${address.stateOrProvince || ''} ${
                                address.country
                            } `,
                        } as IChoiceGroupOption)
                );
            }
        }
        choices.push({ key: -1, text: t('use_address_provided') });
        return choices;
    }

    function validateStateOrProvince(province?: string) {
        if (!validateProvince(addressInputFromFields.country, province)) {
            return t('required_message', { fieldName: t('state_province') });
        }
        return '';
    }

    function validateZipCode(value?: any) {
        if (!value) {
            return t('required_message', { fieldName: t('zip_code') });
        }
        if (!validatePostalCode(addressInputFromFields.country, value)) {
            return t('invalid_postal_code');
        }
        return '';
    }
    
    function isValid() {
        const errors = {
            address1: addressInputFromFields.address1.trim()
                ? ''
                : t('required_message', { fieldName: t('address_1') }),
            firstName: addressInputFromFields.firstName.trim()
                ? ''
                : t('required_message', { fieldName: props.isEnterpriseCustomer === true ? t('first_name_org_label') : t('first_name') }),
            lastName: addressInputFromFields.lastName.trim()
                ? ''
                : t('required_message', { fieldName: props.isEnterpriseCustomer === true ? t('last_name_org_label') : t('last_name') }),
            country: addressInputFromFields.country
                ? ''
                : t('required_message', { fieldName: t('country') }),
            city: addressInputFromFields.city.trim()
                ? ''
                : t('required_message', { fieldName: t('city') }),
            phoneNumber: validatePhoneNumber(addressInputFromFields.phoneNumber.trim()),
            email: validateEmail(addressInputFromFields.email.trim()),
            postalCode: validateZipCode(addressInputFromFields.postalCode.trim()),
            stateOrProvince: validateStateOrProvince(
                addressInputFromFields.stateOrProvince
            ),
        };
        
        setAddressInputFromFields({
            ...addressInputFromFields,
            validation: errors,
        });

        return !Object.keys(errors).some(key => errors[key]);
    }
    
    function handleContinue() {
        if (!isValid()) {
            return;
        }

        logPageAction('Address submitted: Continue button clicked');

        setSubmitting(true);

        if (addressInputFromFields) {
            const addressChosen = addressInputFromFields;
            Object.keys(addressChosen).map(inputVal => addressChosen[inputVal] = typeof addressChosen[inputVal] === 'string' ? addressChosen[inputVal].trim() : addressChosen[inputVal]);
            setAddressInputFromFields(addressChosen);
            dispatch(getAddressValidationData(addressInputFromFields));
        }
    }

    React.useEffect(() => {
        if (
            addressValidationLoadingState.status === LoadingState.success &&
            isSubmitting
        ) {
            setSubmitting(false);
            setShowModal(true);
        }
    }, [addressValidationLoadingState.status]);

    React.useEffect(() => {
        if (props.defaultAddress) {
            const { validation, ...defaultAddress } = props.defaultAddress;
            setAddressInputFromFields({
                ...addressInputFromFields,
                ...defaultAddress,
            });
        }
        else {
            setAddressInputFromFields({
                ...INITIAL_STATE,
                country,
                postalCode,
            });
        }
    }, [props.defaultAddress]);

    return (
        <div>
            <AddressFields
                {...addressInputFromFields}
                disabled={props.readonly}
                isWalkInCenter={props.isWalkInCenter}
                onAddressInputChanges={onAddressInputFieldsChange}
                languageOptions={languageOptions}
                countryOptions={countryOptions}
                provincesOptions={provincesOptions}
                isEnterpriseCustomer={props.isEnterpriseCustomer}
            />
            {showModal && (
                <AddressVerification
                    onChoiceChange={onAddressChoiceChange}
                    options={getAddressChoices()}
                    onCancelChoiceClicked={onCancelAddressChoicePopupClicked}
                    onSaveChoiceClicked={onSaveAddressChoicePopupClicked}
                    onDismissed={onDismissed}
                />
            )}
            <div className={Styles.buttonContainer}>
                {!props.readonly ? (
                    <PrimaryButton
                        className={clsx(
                            Styles.buttonPrimary,
                            Styles.buttonAlign,
                            Styles.continueButton
                        )}
                        disabled={isError || isSubmitting}
                        onClick={handleContinue}
                        ariaLabel={t('continue')}
                        text={t('continue')}
                    />
                ) : null}
                {isDisabled && !isError ? (
                    <Spinner
                        className={Styles.spinnerStyle}
                        labelPosition="bottom"
                        size={SpinnerSize.medium}
                    />
                ) : null}
            </div>
        </div>
    );
}