export default {
    contact_us: 'https://support.microsoft.com/help/4051701/global-customer-service-phone-numbers',
    // trademark page is only available in english
    privacy_and_cookies: 'http://go.microsoft.com/fwlink/?LinkId=521839',
    privacy_statement: 'http://go.microsoft.com/fwlink/?LinkId=521839',
    sitemap: 'https://www.microsoft.com/sitemap.aspx',
    // terms of use page is only available in english
    terms_of_use: 'https://www.microsoft.com/en-us/legal/intellectualproperty/copyright/default.aspx',
    trademarks: 'https://www.microsoft.com/en-us/legal/intellectualproperty/trademarks/en-us.aspx',
    warranty_and_protection: 'https://support.microsoft.com/en-us/topic/warranty-and-protection-plan-terms-conditions-eedf7a23-84a7-1a47-480b-0e10503eedf5' ,
};