import { isWalkInCenterSelected } from './shippingChecks';

export function isBillingAddressRequired(selectedShippingOffers: any, selectedOffer: any, userAgentTier: string): boolean {
    return !!(
        isWalkInCenterSelected(selectedShippingOffers) ||
        isInvoiceRequired(selectedOffer) ||
        userAgentTier === '8'
    );
}

export function isInvoiceRequired(selectedOffer: any): Boolean {
    if (!selectedOffer) {
        return false;
    }
    
    return Object.values(selectedOffer).some(
        (offer: any) => offer.paymentOptions === PaymentOptions.Invoice
    );
}

export enum PaymentOptions {
    /// <summary>
    /// The none option
    /// </summary>
    None = 0,

    /// <summary>
    /// The instrument option
    /// </summary>
    Instrument = 1,

    /// <summary>
    /// The cash option
    /// </summary>
    Cash = 2,

    /// <summary>
    /// The payment on delivery option
    /// </summary>
    PaymentOnDelivery = 4,

    RequiresHold = 8,

    /// <summary>
    /// The invoice option
    /// </summary>
    Invoice = 16,
}