import React, { useState } from 'react';
import clsx from 'clsx';
import {
    Dialog,
    DialogFooter,
    DialogType,
} from 'office-ui-fabric-react/lib/Dialog';
import { useTranslation } from 'react-i18next';
import { logPageAction } from 'src/lib/ApplicationInsights';
import Styles from './closeModal.module.scss';
import { getId } from 'office-ui-fabric-react/lib/Utilities';
import {
    Checkbox, ComboBox, Dropdown, IComboBoxOption,
    IComboBoxStyles, Icon, IDropdownOption, IDropdownStyles,
    MessageBar, MessageBarType, Spinner, SpinnerSize, TooltipHost
} from 'office-ui-fabric-react';
import { getRepairOffer, Progress, saveTravelSrOutcome, saveUserDeviceAndAccessorySelection, saveZipCodeData, secondSrCreation, updateCreateOrderProgress } from './../../store/orderCreation';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingState } from './../../store/actionStatus';
import { RepairOptions, TravelOutcomeData } from './../../constants/travelOutcome';
import { devicePartSelector, deviceSelector, deviceSymptomSelector } from './../../store/deviceInfo/selectors';
import { generatePartOptions } from './../../views/createOrder/SelectRepairItems/ProblemSelectionProps';
import tooltipIcon from './../../assets/tooltip-copy-3@2x.png';
import history from 'src/history';
import { userAgentTierSelector, userSoldToIdSelector } from './../../store/user';
import { countriesSelector } from './../../components/ServiceRequestAndDeviceLookup/selector';
import { putSelectedCountryData } from './../../store/countries';


export default function CloseOrderModal(props: any) {

    enum Actions {
        Cancel = 'Cancel',
        Complete = 'Complete',
    }

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [hide, hideDialog] = useState(false);
    const [isTermsChecked, setIsTermsChecked] = useState(false);
    const [selectedRepairOption, setSelectedRepairOption] = React.useState<IDropdownOption>();
    const {claimOrderId, travelSROrderStatus, symptomCode, zipCode, repairDetails, countryCode, deviceInfo} = props;
    const updating = travelSROrderStatus.status === LoadingState.started;
    const updateFailed = travelSROrderStatus.status === LoadingState.rejected;
    const updateDone = travelSROrderStatus.status === LoadingState.success;
    const [selectedAction, setSelectedAction] = useState<Actions>();
    const [inventoryOptions, setInventoryOptions] = React.useState<IComboBoxOption[]>();
    const [selectedInventoryOptions, setSelectedInventoryOptions] = React.useState<any>([]);
    const selectedInventoryOptionKeys: string[] = selectedInventoryOptions.map((part: IComboBoxOption) => part.key);
    const userAgent: string = useSelector(state => userAgentTierSelector(state));
    const soldToId: string = useSelector(state => userSoldToIdSelector(state));
    const problemTypeOptions: IComboBoxOption[] = useSelector(state => deviceSymptomSelector(state))
        .map((symptom: any) => (
            {
                key: symptom.offerToken,
                text: symptom.name,
                data: symptom,
            }
        ))
        .sort(
            (part: IComboBoxOption, part2: IComboBoxOption) => part.text.localeCompare(part2.text)
        );
    const selectedProblemType = problemTypeOptions.find(problemType => problemType.data.symptomCode === symptomCode);
    const device = useSelector(state => deviceSelector(state));
    const devicePart = useSelector(state => devicePartSelector(state));
    const devicePartsOptions: IComboBoxOption[] = generatePartOptions(devicePart, selectedProblemType, t);
    const allCountries = useSelector(state => countriesSelector(state));

    React.useEffect(() => {
        setInventoryOptions(devicePartsOptions);
        if(updateDone){
            setTimeout(() => {
                hideDialog(true);
            }, 3000);
        }
    }, [updateDone]);

    const findCountryDetails = (selectCountryCode:string) => {
        return selectCountryCode && Array.isArray(allCountries) && allCountries.find((country: any) => {
            return country
                && (
                    (country.ISOCode && country.ISOCode.toUpperCase().trim() === selectCountryCode.trim())
                    || (country.ISOCountryShortCode && country.ISOCountryShortCode.toUpperCase().trim() === selectCountryCode)
                );
        });
    };

    function  closeDialog() {
        logPageAction('Close Order Modal back button clicked');
        props.orderCloseModalClose();
        hideDialog(true);
    }

    function saveAndClose() {
        logPageAction('Close Order Modal close button clicked');

        switch (selectedRepairOption?.key) {
            case RepairOptions.hardwareRepair: {
                // update country data for second SR
                const countryData = findCountryDetails(countryCode);
                const selCountryData = { countryCode:countryData.ISOCode, countryName: countryData.ISOName, countryShortCode: countryData.ISOCountryShortCode };
                dispatch(putSelectedCountryData(selCountryData));

                // update parent data for second sr
                const selectedParent = {
                    name: deviceInfo.localizedName,
                    serialNumber: deviceInfo.serialNumber,
                    sku: deviceInfo.partNumber,
                };
                const selectedAccessory:any = []; //temp
                
                dispatch(
                    saveUserDeviceAndAccessorySelection({
                        parent: selectedParent,
                        accessory: selectedAccessory,
                    })
                );
                // update deviceItems for repair offers
                const isFullDeviceRepair: boolean = false;
                const deviceItems = selectedInventoryOptions.reduce((options: any, part: any) => {
                    return {
                        ...options,
                        [part.data.symptomCode]: {
                            ...part.data,
                            repairDetails,
                            onDemandFru: false,
                            problemType: (selectedProblemType && selectedProblemType.data),
                            isCruOffer: false,
                        },
                    };
                }, {});
                
                const repairDetailData: {} = {
                    isFullDeviceRepair,
                    deviceItems,
                    accessories: {},
                };
                dispatch(getRepairOffer({
                    repairDetails: repairDetailData,
                    additionalInfo: {
                        userAgent,
                        countryCode,
                        soldToId,
                        postalCode: zipCode,
                        serialNumber: device.serialNumber,
                    },
                }));
                
                // save zip code for second sr
                dispatch(saveZipCodeData({zipCode}));
                dispatch(updateCreateOrderProgress(Progress.RepairOfferSelection));
                dispatch(secondSrCreation());
                history.push('/device/createOrder');
                break;
            }
            case RepairOptions.softwareRepair: {
                break;
            }
            case RepairOptions.incompleteRepair: {
                dispatch(saveTravelSrOutcome({
                    claimOrderId,
                    action: Actions.Complete,
                    travelSrOutcome: selectedRepairOption.key,
                }));
                setSelectedAction(Actions.Complete);
                break;
            }
            case RepairOptions.unsuccessfulRepair: {
                dispatch(saveTravelSrOutcome({
                    claimOrderId,
                    action: Actions.Complete,
                    travelSrOutcome: selectedRepairOption.key,
                }));
                setSelectedAction(Actions.Complete);
                break;
            }
            case RepairOptions.orderCancel: {
                dispatch(saveTravelSrOutcome({
                    claimOrderId,
                    action: Actions.Cancel,
                    travelSrOutcome: selectedRepairOption.key,
                }));
                setSelectedAction(Actions.Cancel);
                break;
            }
        }

        //hideDialog(true);
        // save(true);
    }

    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 300 },
    };
      
    const options: IDropdownOption[] = TravelOutcomeData.map(outcome => ({ key: outcome.key, text: t(outcome.text) }));

    function onCheckboxChange(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {
        setIsTermsChecked(isChecked ? isChecked : false);
    }

    const onRepairOptionSelect = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        setSelectedRepairOption(item);
        setIsTermsChecked(false);
    };

    const PartComboBoxStyle: Partial<IComboBoxStyles> = {
        container: {
            float: 'left',
            minWidth: '400px',
        },
        root: {
            width: 244,
        },
        optionsContainer: {
            maxHeight: 300,
            width: 243,
        },
    };

    const isCancelOrder =  selectedRepairOption?.key === RepairOptions.orderCancel ? true : false;

    const titleId: string = getId('dialogLabel');
    const subTextId: string = getId('subTextLabel');

    function customLabelRenderOption(item: any) {
        return (
            <div className={Styles.menuItemHeader}>
                {item.text}
                {item.data && item.data.requiredWarning &&
                    <TooltipHost
                        className={Styles.toolTip}
                        content={t('contact_crew_support')}
                    >
                        <Icon
                            className={Styles.warningIcon}
                            iconName="warning"
                        />
                    </TooltipHost>
                }
            </div>
        );
    }

    function onPartSelection(event: any, option: IComboBoxOption | undefined) {
        if (!option) {
            return;
        }

        logPageAction('Select Repair Items combo box item selected', { itemsSelected: option.selected });

        if (option.selected) {
            const {selected, ...removeSelectedFromOption} = option;
            setSelectedInventoryOptions([...selectedInventoryOptions, removeSelectedFromOption]);
        } else {
            setSelectedInventoryOptions(
                selectedInventoryOptions.filter(
                    (part: IComboBoxOption) => part.key !== option.key
                )
            );
        }
    }

    return (
        <section>
            <Dialog
                hidden={hide}
                onDismiss={closeDialog}
                dialogContentProps={{
                    titleId,
                    subTextId,
                    type: DialogType.normal,
                    closeButtonAriaLabel: t('close'),
                    title: t('travel_outcome'),
                    subText: t('travel_outcome_message'),
                }}
                modalProps={{
                    titleAriaId: titleId,
                    subtitleAriaId: subTextId,
                    isBlocking: true,
                    containerClassName: `${Styles.popup}`,
                }}
            >
                <div className="ms-Grid-row">
                    <div className={clsx('ms-Grid-col', 'ms-sm6')}>
                        <Dropdown
                            placeholder={t('select_an_option')}
                            label={t('please_select_option_below')}
                            options={options}
                            styles={dropdownStyles}
                            required={true}
                            selectedKey={selectedRepairOption ? selectedRepairOption.key : undefined}
                            onChange={onRepairOptionSelect}
                            disabled={updateDone}
                        />
                    </div>
                    {
                        (
                            selectedRepairOption?.key === RepairOptions.incompleteRepair ||
                            selectedRepairOption?.key === RepairOptions.orderCancel ||
                            selectedRepairOption?.key === RepairOptions.unsuccessfulRepair
                        ) &&
                            <div className={clsx('ms-Grid-col', 'ms-sm6')}>
                                <Checkbox
                                    label={selectedRepairOption?.key === RepairOptions.orderCancel ?
                                        t('agree_to_cancel_order') :  t('agree_to_close_order')}
                                    onChange={onCheckboxChange}
                                    className={Styles.marginTopCheckbox}
                                    checked={isTermsChecked}
                                    disabled={updateDone}
                                    />
                            </div>
                    }
                    {
                        (
                            selectedRepairOption?.key === RepairOptions.hardwareRepair
                        ) &&
                        <div className={clsx('ms-Grid-col', 'ms-sm6')}>
                            <ComboBox
                                styles={PartComboBoxStyle}
                                selectedKey={selectedInventoryOptionKeys}
                                options={inventoryOptions}
                                label={t('replace_component_inventory')}
                                placeholder={t('select_components')}
                                onRenderOption={customLabelRenderOption}
                                multiSelect={true}
                                onChange={onPartSelection}
                                className={Styles.partSelectionCombo}
                                id="replace_component_inventory_travelsr"
                            />
                            <TooltipHost
                                hostClassName={Styles.elementToolTip}
                                aria-label={t('replace_component_inventory_toolip_text')}
                                content={t('replace_component_inventory_toolip_text')}>
                                <img
                                    alt={t('replace_component_inventory_toolip_text')}
                                    aria-label={t('replace_component_inventory_toolip_text')}
                                    className={Styles.toolTipIcon}
                                    src={tooltipIcon}
                                    tabIndex={0}
                                    aria-describedby={t('replace_component_inventory_toolip_text')}
                                />
                            </TooltipHost>
                        </div>
                    }
                </div>
                <p className={Styles.selectionUnable}>{t('selection_unable_complete_order')}</p>
                <p>{t('address_validation_select_continue')}</p>
                {
                    updateDone && !hide &&
                    <MessageBar
                        messageBarType={MessageBarType.success}
                        isMultiline={false}
                    >
                        {selectedAction === Actions.Complete && t('your_order_is_closed')}
                        {selectedAction === Actions.Cancel && t('your_order_is_cancelled')}
                    </MessageBar>
                }
                <ModalFooter />
            </Dialog>
        </section>
    );

    function ModalFooter(props: any) {
        return (
            <DialogFooter>
                    <button
                        name="backBtn"
                        className={clsx(Styles.buttonSecondary, Styles.button)}
                        onClick={closeDialog}
                    >
                        {t('back')}
                    </button>

                    { (selectedRepairOption && !updateDone) &&
                        <span>
                            { (selectedRepairOption?.key === RepairOptions.hardwareRepair ||
                                    selectedRepairOption?.key === RepairOptions.softwareRepair) ?
                                <button
                                    name="nextBtn"
                                    className={clsx(Styles.buttonPrimary, Styles.button)}
                                    onClick={saveAndClose}
                                >
                                    {t('next')}
                                </button> :
                                <button
                                    name="closeBtn"
                                    className={updateFailed ? clsx(Styles.rejected, Styles.button) : clsx(Styles.buttonPrimary, Styles.button)}
                                    onClick={saveAndClose}
                                    disabled={!isTermsChecked || updating}
                                >
                                   {t(updateFailed ? 'try_again' : isCancelOrder ? 'cancel_order': 'close_order')}
                                </button>
                            }
                            { updating &&
                                <Spinner
                                    className={clsx(Styles.spinner, Styles.spinnerInModal)}
                                    size={SpinnerSize.medium}
                                />
                            }
                        </span>
                    }
                </DialogFooter>
        );
        
    }
}