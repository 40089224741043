import React from 'react';
import { Link } from 'react-router-dom';
import { IRoute } from './../../views';
import { History } from 'history';
import Styles from './breadcrumbs.module.scss';

export interface BreadcrumbsProps {
    routes: IRoute[];
    history: History;
}

export function Breadcrumbs({ routes, history }: BreadcrumbsProps) {
    const breadCrumbs: string[] = [];
    const separator = '/';
    if (history.location.pathname === separator) {
        breadCrumbs.push(separator);
    }
    else {
        const pathArray: string[] = history.location.pathname.split(separator);

        let temp = '';
        breadCrumbs.push(separator);
        for (let i = 1; i <= pathArray.length - 1; i++) {
            temp = temp + separator + pathArray[i];
            breadCrumbs.push(temp);
        }
    }

    return (
        <>
            {
                React.Children.toArray(
                    breadCrumbs.map((breadcrumb, index) => (
                        <span>
                            {index === 0 ? '' : ' > '}
                            {index !== breadCrumbs.length - 1 ?
                                (<Link className={Styles.anchorLink} to={(routes.filter(route => (breadcrumb === route.path)))[0].path}>
                                    {(routes.filter(route => (breadcrumb === route.path)))[0].crumbText}</Link>) :
                                (<>{(routes.filter(route => (breadcrumb === route.path)))[0].crumbText}</>)
                            }
                        </span>
                    ))
                )
            }
        </>
    );
}