import { ORDER_FROM_ROBO_V2 } from './../../../constants/common';
import { isWalkInCenterSelected } from './../../../helpers/shippingChecks';
import { logException } from './../../../lib/ApplicationInsights';
import CorrelationVector from './../../../lib/CorrelationVector';

import { ICheckoutPayload, IItem } from './checkoutTypes';

const DEFAULT_LANGUAGE = 'en-US';
const DEFAULT_PHONE_NUMBER = '000 000 0000';

export interface TransformCheckoutPayloadProps {
    agentInfo: any;
    billingAddress: any;
    billToEmail: any;
    device: any;
    deviceShippingOffers: any;
    selectedShippingOffers: any;
    shippingAddress: any;
    orderInfo: any;
    customerServiceLocationAddress: any;
    customerComments: any;
    onSiteSoldToShipTo: any;
    softwareRepairDetails: any;
}

// https://www.typescriptlang.org/docs/handbook/basic-types.html#never
// Use return type of never to indicate that the function may throw an error
export function transformCheckoutPayload(data: TransformCheckoutPayloadProps): ICheckoutPayload | never {
    const {
        agentInfo,
        billingAddress,
        billToEmail,
        device,
        deviceShippingOffers,
        selectedShippingOffers,
        shippingAddress,
        orderInfo,
    } = data;
    
    const customerInfo = orderInfo && orderInfo.customerInfo || {};
    
    //check whether this order is CRU or not
    let isCruOrder = false;
    const { isFullDeviceRepairOrder } = orderInfo.repairItems;
    if(!isFullDeviceRepairOrder){
        const deviceList = orderInfo.repairItems.deviceItems;
        for (const item in deviceList) {
            if (deviceList[item].hasOwnProperty('isCruOffer') && deviceList[item]['isCruOffer'] === true) {
                isCruOrder = true;
            }
        }
    }

    return {
        AgentEmailAddress: agentInfo.email,
        Comments: ORDER_FROM_ROBO_V2,
        ClaimOrderId: '',
        CustomerIdentity: {
            CustomerId: device.registration.customerId,
            EmailAddress: customerInfo && customerInfo.microsoftAccount,
            FirstName: customerInfo.firstName,
            JarvisOwnerId: '',
            LastName: customerInfo.lastName,
            PassportId: device.registration.passportId,
        },
        CustomerLocale: shippingAddress
            ? shippingAddress.language
            : DEFAULT_LANGUAGE,
        CustomerNotificationDetail: '',
        Items: [
            ...generateDeviceLineItems(
                data,
                selectedShippingOffers,
                deviceShippingOffers
            ) ?? [],
            ...generateAccessoryLineItems(data, selectedShippingOffers) ?? [],
        ],
        PaymentDetails: {
            BillTo: {
                EmailId: billingAddress.email || billToEmail,
                AddressLine1:
                    billingAddress.address1 || shippingAddress.address1,
                AddressLine2:
                    billingAddress.address2 || shippingAddress.address2 || '',
                AddressLine3: '',
                City: billingAddress.city || shippingAddress.city,
                Country: billingAddress.country || shippingAddress.country,
                FirstName:
                    billingAddress.firstName || shippingAddress.firstName,
                LastName: billingAddress.lastName || shippingAddress.lastName,
                PhoneNumber:
                    billingAddress.phoneNumber ||
                    shippingAddress.phoneNumber ||
                    DEFAULT_PHONE_NUMBER,
                PostalCode:
                    billingAddress.postalCode || shippingAddress.postalCode,
                Region:
                    billingAddress.stateOrProvince || shippingAddress.stateOrProvince,
                SapId: agentInfo.soldToCustomerNbr,
                VatId: billingAddress.vatId || shippingAddress.vatId,
                CustomerPO: billingAddress.customerPO || shippingAddress.customerPO,
            },
            CpfId: '',
            Currency: getCurrency(data),
            GrandTotalAmount: 0,
            GuestId: '',
            Puid: '',
            PurchaseAmount: 0,
            PurchaseTaxAmount: 0,
            ShippingAmount: 0,
            ShippingTaxAmount: 0,
        },
        PurchaseOrderId: '',
        ServiceOrderNumber: 0,
        ShipmentDetails: {
            ShipTo: {
                AddressLine1: shippingAddress.address1,
                AddressLine2: shippingAddress.address2 || '',
                AddressLine3: '',
                City: shippingAddress.city,
                Country: shippingAddress.country,
                EmailId: shippingAddress.email,
                FirstName: shippingAddress.firstName,
                LastName: shippingAddress.lastName,
                PhoneNumber:
                    shippingAddress.phoneNumber || DEFAULT_PHONE_NUMBER,
                PostalCode: shippingAddress.postalCode,
                Region: shippingAddress.stateOrProvince,
                SapId: agentInfo.shiptoCustomerNbr,
                VatId: shippingAddress.vatId,
                CustomerPO: shippingAddress.customerPO,
            },
        },
        TenantId: '',
        Tier: agentInfo.tierName,
        IsPOPValidated: isCruOrder,
        IsCRUExchangeOrder: isCruOrder,
    };
}

export function transformCheckoutSoftwareRepairPayload(data: TransformCheckoutPayloadProps): ICheckoutPayload | never {
    const {
        agentInfo,
        billingAddress,
        billToEmail,
        device,
        deviceShippingOffers,
        selectedShippingOffers,
        shippingAddress,
        orderInfo,
        softwareRepairDetails,
    } = data;

    const customerInfo = orderInfo && orderInfo.customerInfo || {};
    
    //check whether this order is CRU or not
    let isCruOrder = false;
    const { isFullDeviceRepairOrder } = orderInfo.repairItems;
    if(!isFullDeviceRepairOrder){
        const deviceList = orderInfo.repairItems.deviceItems;
        for (const item in deviceList) {
            if (deviceList[item].hasOwnProperty('isCruOffer') && deviceList[item]['isCruOffer'] === true) {
                isCruOrder = true;
            }
        }
    }
    return {
        AgentEmailAddress: agentInfo.email,
        Comments: ORDER_FROM_ROBO_V2,
        ClaimOrderId: '',
        CustomerIdentity: {
            CustomerId: device.registration.customerId,
            EmailAddress: customerInfo && customerInfo.microsoftAccount,
            FirstName: customerInfo.firstName,
            JarvisOwnerId: '',
            LastName: customerInfo.lastName,
            PassportId: device.registration.passportId,
        },
        CustomerLocale: shippingAddress
            ? shippingAddress.language
            : DEFAULT_LANGUAGE,
        CustomerNotificationDetail: '',
        Items: [
            ...generateSoftwareOrderLineItems(
                data,
                selectedShippingOffers,
                deviceShippingOffers,
                softwareRepairDetails
            ) ?? [],
            ...generateAccessoryLineItems(data, selectedShippingOffers) ?? [],
        ],
        PaymentDetails: {
            BillTo: {
                EmailId: billingAddress.email || billToEmail,
                AddressLine1:
                    billingAddress.address1 || shippingAddress.address1,
                AddressLine2:
                    billingAddress.address2 || shippingAddress.address2 || '',
                AddressLine3: '',
                City: billingAddress.city || shippingAddress.city,
                Country: billingAddress.country || shippingAddress.country,
                FirstName:
                    billingAddress.firstName || shippingAddress.firstName,
                LastName: billingAddress.lastName || shippingAddress.lastName,
                PhoneNumber:
                    billingAddress.phoneNumber ||
                    shippingAddress.phoneNumber ||
                    DEFAULT_PHONE_NUMBER,
                PostalCode:
                    billingAddress.postalCode || shippingAddress.postalCode,
                Region:
                    billingAddress.stateOrProvince || shippingAddress.stateOrProvince,
                SapId: agentInfo.soldToCustomerNbr,
                VatId: billingAddress.vatId || shippingAddress.vatId,
                CustomerPO: billingAddress.customerPO || shippingAddress.customerPO,
            },
            CpfId: '',
            Currency: getCurrency(data),
            GrandTotalAmount: 0,
            GuestId: '',
            Puid: '',
            PurchaseAmount: 0,
            PurchaseTaxAmount: 0,
            ShippingAmount: 0,
            ShippingTaxAmount: 0,
        },
        PurchaseOrderId: '',
        ServiceOrderNumber: 0,
        ShipmentDetails: {
            ShipTo: {
                AddressLine1: shippingAddress.address1,
                AddressLine2: shippingAddress.address2 || '',
                AddressLine3: '',
                City: shippingAddress.city,
                Country: shippingAddress.country,
                EmailId: shippingAddress.email,
                FirstName: shippingAddress.firstName,
                LastName: shippingAddress.lastName,
                PhoneNumber:
                    shippingAddress.phoneNumber || DEFAULT_PHONE_NUMBER,
                PostalCode: shippingAddress.postalCode,
                Region: shippingAddress.stateOrProvince,
                SapId: agentInfo.shiptoCustomerNbr,
                VatId: shippingAddress.vatId,
                CustomerPO: shippingAddress.customerPO,
            },
        },
        TenantId: '',
        Tier: agentInfo.tierName,
        IsPOPValidated: isCruOrder,
        IsCRUExchangeOrder: isCruOrder,
    };
}

export function transformCheckoutOnSiteRepairPayload(data: TransformCheckoutPayloadProps): ICheckoutPayload | never {
    const {
        agentInfo,
        device,
        deviceShippingOffers,
        selectedShippingOffers,
        orderInfo,
        customerServiceLocationAddress,
        customerComments,
        onSiteSoldToShipTo,
    } = data;
    
    const customerInfo = orderInfo && orderInfo.customerInfo || {};
    
    //check whether this order is CRU or not
    let isCruOrder = false;
    const { isFullDeviceRepairOrder } = orderInfo.repairItems;
    if(!isFullDeviceRepairOrder){
        const deviceList = orderInfo.repairItems.deviceItems;
        for (const item in deviceList) {
            if (deviceList[item].hasOwnProperty('isCruOffer') && deviceList[item]['isCruOffer'] === true) {
                isCruOrder = true;
            }
        }
    }

    return {
        AgentEmailAddress: agentInfo.email,
        Comments: ORDER_FROM_ROBO_V2,
        ClaimOrderId: '',
        CustomerIdentity: {
            CustomerId: device.registration.customerId,
            EmailAddress: customerInfo && customerInfo.microsoftAccount,
            FirstName: customerInfo.firstName,
            JarvisOwnerId: '',
            LastName: customerInfo.lastName,
            PassportId: device.registration.passportId,
        },
        CustomerLocale: customerServiceLocationAddress
            ? customerServiceLocationAddress.language
            : DEFAULT_LANGUAGE,
        CustomerNotificationDetail: '',
        Items: [
            ...generateDeviceLineItems(
                data,
                selectedShippingOffers,
                deviceShippingOffers
            ) ?? [],
            ...generateAccessoryLineItems(data, selectedShippingOffers) ?? [],
        ],
        PaymentDetails: {
            BillTo: {
                EmailId: customerServiceLocationAddress.email,
                AddressLine1: customerServiceLocationAddress.address1,
                AddressLine2: customerServiceLocationAddress.address2 || '',
                AddressLine3: '',
                City: customerServiceLocationAddress.city,
                Country: customerServiceLocationAddress.country,
                FirstName: customerServiceLocationAddress.firstName,
                LastName: customerServiceLocationAddress.lastName,
                PhoneNumber: customerServiceLocationAddress.phoneNumber,
                PostalCode: customerServiceLocationAddress.postalCode,
                Region: customerServiceLocationAddress.stateOrProvince,
                SapId: agentInfo.soldToCustomerNbr,
                VatId: customerServiceLocationAddress.vatId,
                CustomerPO: customerServiceLocationAddress.customerPO,
            },
            CpfId: '',
            Currency: getCurrency(data),
            GrandTotalAmount: 0,
            GuestId: '',
            Puid: '',
            PurchaseAmount: 0,
            PurchaseTaxAmount: 0,
            ShippingAmount: 0,
            ShippingTaxAmount: 0,
        },
        PurchaseOrderId: '',
        ServiceOrderNumber: 0,
        CustomerComments: customerComments,
        ServiceLocationDetails : {
            AddressLine1: customerServiceLocationAddress.address1,
            AddressLine2: customerServiceLocationAddress.address2 || '',
            City: customerServiceLocationAddress.city,
            Country: customerServiceLocationAddress.country,
            EmailId: customerServiceLocationAddress.email,
            FirstName: customerServiceLocationAddress.firstName,
            LastName: customerServiceLocationAddress.lastName,
            PhoneNumber: customerServiceLocationAddress.phoneNumber,
            PostalCode: customerServiceLocationAddress.postalCode,
            Region: customerServiceLocationAddress.stateOrProvince,
            VatId: customerServiceLocationAddress.vatId,
            CustomerPO: customerServiceLocationAddress.customerPO,
            SecondaryContactName: customerServiceLocationAddress.secondaryContactName,
            SecondaryContactPhoneNumber: customerServiceLocationAddress.secondaryContactPhoneNumber,
            SapId: onSiteSoldToShipTo.shiptoCustomerNbr,
        },
        TenantId: '',
        Tier: agentInfo.tierName,
        IsPOPValidated: isCruOrder,
        IsCRUExchangeOrder: isCruOrder,
        IsOnsiteCheckOut: true,
        PartnerDetails: {
            PartnerId: onSiteSoldToShipTo.soldToCustomerNbr,
            Name: onSiteSoldToShipTo.soldToCustomerName,
            Address: {
                AddressLine1: onSiteSoldToShipTo.soldToAddressLineOneText || '',
                AddressLine2: onSiteSoldToShipTo.soldToAddressLineTwoText || '',
                AddressLine3: onSiteSoldToShipTo.soldToAddressLineThreeText || '',
                City: onSiteSoldToShipTo.soldToCityName || '',
                Country: onSiteSoldToShipTo.soldToCountryCode || '',
                FirstName: onSiteSoldToShipTo.soldToCustomerName || '',
                PostalCode: onSiteSoldToShipTo.soldToPostalCode || '',
                Region: onSiteSoldToShipTo.soldToStateProvinceCode || '',
            },
            EmailAddress: onSiteSoldToShipTo.soldToEmail,
        },
    };
}



export function transformCheckoutReasonForNotSelectingPayload(data: any): any | never {
    const {
        agentInfo,
        orderInfo,
        device,
    } = data;
    return {
        AgentEmailAddress: agentInfo.email,
        Tier: agentInfo.tierName,
        ClaimOrderId: device.claims[0].claimOrderId,
        ReasonForNoRepairDescription:orderInfo.reasonForNotSelectingOffers.repairComments,
        ReasonForNoRepair:orderInfo.reasonForNotSelectingOffers.repairReason.text,
    };
}
function generateAccessoryLineItems(
    data: any,
    selectedShippingOffers: any
): IItem[] | undefined {
    const { device, orderInfo } = data;

    const accessoriesSKUs = Object.keys(orderInfo.repairItems.accessories);

    return accessoriesSKUs.reduce((reducedLineItems: any, sku): IItem[] => {
        const selectedRepairInfo = orderInfo.repairItems.accessories[sku];
        const selectedRepairInfoData = selectedRepairInfo.data;
        const symptomCode = selectedRepairInfoData.symptomCode;
        const repairOffer = orderInfo.selectedRepairOffers[symptomCode];

        if (!repairOffer || !Object.keys(repairOffer).length) {
            return reducedLineItems;
        }

        if (!selectedShippingOffers || !selectedShippingOffers[repairOffer.offerId]) {
            throw new Error(`Internal Error: Offer Configuration Error. Please contact CREW configuration support. Could not find ${repairOffer.offerId} in selectedShippingOffers`);
        }

        return [
            ...reducedLineItems,
            {
                AccessoryType: symptomCode,
                isComponent: false,
                CancelReasonCode: '',
                ClaimReference: '',
                ClaimType: '',
                Comments: selectedRepairInfo.problemDescription,
                LineItemId: '',
                ModernLineItemStatus: '',
                PurchaseOrderLineItemId: '',
                Quantity: 1,
                RepairOffer: {
                    ExtendedPrice: 0,
                    Id: repairOffer.offerId,
                    ListPrice: 3108,
                },
                ReplacementSerialNumber: '',
                SalesDocument: '',
                SerialNumber: device.serialNumber,
                ServiceRequestId: 0,
                ShippingOffer: {
                    Id: selectedShippingOffers[repairOffer.offerId].offerSku,
                },
                SymptomCode: symptomCode,
                SDTRunId: device.deviceSDTRunDetail?.sdtRunId,
                NumberOfRepairAttempts: selectedRepairInfo.numberOfRepairAttempts,
                RepairDetails: selectedRepairInfo.repairDetails,
            },
        ];
    }, []);
}

function generateDeviceLineItems(
    data: any,
    selectedShippingOffers: any,
    deviceShippingOffers: any
): IItem[] | undefined {
    const { device, orderInfo } = data;

    const deviceItemsSKUs = Object.keys(orderInfo.repairItems.deviceItems);
    const { isFullDeviceRepair,deviceItems, isSoftwareOrder } = orderInfo.repairItems;
    return deviceItemsSKUs.reduce((reducedLineItems: any, sku): IItem[] => {
        const selectedRepairInfo = orderInfo.repairItems.deviceItems[sku];
        const SafetyIssueInfo = Object.keys(orderInfo.safetyIssueInfo).length ? orderInfo.safetyIssueInfo : null;
        const symptomCode = (isFullDeviceRepair || isSoftwareOrder) ? selectedRepairInfo.problemType.symptomCode : selectedRepairInfo.symptomCode;
        const repairOffer = orderInfo.selectedRepairOffers[symptomCode];

        //if it is onDemand Order, then only send lineitem with repair offer null
        let onDemandFruPartsPayload = [];

        onDemandFruPartsPayload = !isFullDeviceRepair && deviceItems && Object.values(deviceItems).filter((part:any) => part.onDemandFru === true);
        if(onDemandFruPartsPayload.length === 0){
            if (!repairOffer || !Object.keys(repairOffer).length) {
                return reducedLineItems;
            }
        }

        //if ondemandFRU item selected as 'Do not repair' then dont add to the items list
        if(selectedRepairInfo.onDemandFru === true && !Object.keys(repairOffer).length){
            return reducedLineItems;
        }

        const tempPayload: any = {
            AccessoryType: !(isFullDeviceRepair || isSoftwareOrder) ? symptomCode : null,
            isComponent : isFullDeviceRepair ? false : true,
            CancelReasonCode: '',
            ClaimReference: '',
            ClaimType: '',
            Comments: selectedRepairInfo.problemDescription,
            ComponentDeviceInfo: !isFullDeviceRepair && selectedRepairInfo.regex ? {
                SerialNumber: selectedRepairInfo.serialNumber,
            } : null,
            LineItemId: '',
            ModernLineItemStatus: '',
            PurchaseOrderLineItemId: '',
            Quantity: 1,
            RepairOffer: !repairOffer || !Object.keys(repairOffer).length ? null : {
                Id: repairOffer.offerId,
            },
            ReplacementSerialNumber: !repairOffer || !Object.keys(repairOffer).length ? null : selectedRepairInfo.isCruOffer === true ? null : getReplacementSerialNumber(orderInfo, sku, symptomCode),
            SalesDocument: '',
            SerialNumber: device.serialNumber,
            ServiceRequestId: 0,
            ShippingOffer: !repairOffer || !Object.keys(repairOffer).length ? null : {
                Id: getShippingOfferSku({
                    selectedShippingOffers,
                    deviceShippingOffers,
                    offerId: repairOffer.offerId,
                }),
            },
            SymptomCode: (selectedRepairInfo.problemType && selectedRepairInfo.problemType.symptomCode),
            SDTRunId: device.deviceSDTRunDetail?.sdtRunId,
            NumberOfRepairAttempts: selectedRepairInfo.numberOfRepairAttempts,
            RepairDetails: selectedRepairInfo.repairDetails,
        };

        // if(selectedRepairInfo.isCruOffer === true){
        //     tempPayload.IsPOPValidated = true;
        //     tempPayload.IsCRUExchangeOrder = true;
        // }

        if(SafetyIssueInfo) tempPayload.SafetyIssueInfo = SafetyIssueInfo;
        return [
            ...reducedLineItems,
            tempPayload,
        ];
    }, []);
}

function generateSoftwareOrderLineItems(
    data: any,
    selectedShippingOffers: any,
    deviceShippingOffers: any,
    softwareRepairDetails: any
): IItem[] | undefined {
    const { device, orderInfo } = data;

    const deviceItemsSKUs = Object.keys(orderInfo.repairItems.deviceItems);
    const { isFullDeviceRepair,deviceItems, isSoftwareOrder } = orderInfo.repairItems;
    return deviceItemsSKUs.reduce((reducedLineItems: any, sku): IItem[] => {
        const selectedRepairInfo = orderInfo.repairItems.deviceItems[sku];
        const SafetyIssueInfo = Object.keys(orderInfo.safetyIssueInfo).length ? orderInfo.safetyIssueInfo : null;
        const symptomCode = (isFullDeviceRepair || isSoftwareOrder) ? selectedRepairInfo.problemType.symptomCode : selectedRepairInfo.symptomCode;
        const repairOffer = orderInfo.selectedRepairOffers[symptomCode];

        //if it is onDemand Order, then only send lineitem with repair offer null
        let onDemandFruPartsPayload = [];

        onDemandFruPartsPayload = !isFullDeviceRepair && deviceItems && Object.values(deviceItems).filter((part:any) => part.onDemandFru === true);
        if(onDemandFruPartsPayload.length === 0){
            if (!repairOffer || !Object.keys(repairOffer).length) {
                return reducedLineItems;
            }
        }

        //if ondemandFRU item selected as 'Do not repair' then dont add to the items list
        if(selectedRepairInfo.onDemandFru === true && !Object.keys(repairOffer).length){
            return reducedLineItems;
        }

        const tempPayload: any = {
            AccessoryType: !(isFullDeviceRepair || isSoftwareOrder) ? symptomCode : null,
            isComponent : isFullDeviceRepair ? false : true,
            CancelReasonCode: '',
            ClaimReference: '',
            ClaimType: '',
            Comments: selectedRepairInfo.problemDescription,
            ComponentDeviceInfo: !isFullDeviceRepair && selectedRepairInfo.regex ? {
                SerialNumber: selectedRepairInfo.serialNumber,
            } : null,
            LineItemId: '',
            ModernLineItemStatus: '',
            PurchaseOrderLineItemId: '',
            Quantity: 1,
            RepairOffer: !repairOffer || !Object.keys(repairOffer).length ? null : {
                Id: repairOffer.offerId,
            },
            ReplacementSerialNumber: !repairOffer || !Object.keys(repairOffer).length ? null : selectedRepairInfo.isCruOffer === true ? null : getReplacementSerialNumber(orderInfo, sku, symptomCode),
            SalesDocument: '',
            SerialNumber: device.serialNumber,
            ServiceRequestId: 0,
            ShippingOffer: !repairOffer || !Object.keys(repairOffer).length ? null : {
                Id: getShippingOfferSku({
                    selectedShippingOffers,
                    deviceShippingOffers,
                    offerId: repairOffer.offerId,
                }),
            },
            SymptomCode: (selectedRepairInfo.problemType && selectedRepairInfo.problemType.symptomCode),
            SDTRunId: device.deviceSDTRunDetail?.sdtRunId,
            NumberOfRepairAttempts: selectedRepairInfo.numberOfRepairAttempts,
            RepairDetails: selectedRepairInfo.repairDetails,
            SoftwareRepairDetails: softwareRepairDetails,
        };

        // if(selectedRepairInfo.isCruOffer === true){
        //     tempPayload.IsPOPValidated = true;
        //     tempPayload.IsCRUExchangeOrder = true;
        // }

        if(SafetyIssueInfo) tempPayload.SafetyIssueInfo = SafetyIssueInfo;
        return [
            ...reducedLineItems,
            tempPayload,
        ];
    }, []);
}

function getCurrency({ orderInfo }: TransformCheckoutPayloadProps['orderInfo']) {
    const keys = Object.keys(orderInfo.selectedRepairOffers);
    if (keys && keys.length > 0) {
        const repairOfferKey:any = keys.find( key => Object.keys(orderInfo.selectedRepairOffers[key]).length > 0);
        const repairOffer = orderInfo.selectedRepairOffers[repairOfferKey];
        return Object.keys(repairOffer).length > 0 && repairOffer.currency;
    }
    return 'USD';
}

function getReplacementSerialNumber(orderInfo: any, sku: string, symptomCode: string) {
    const {
        deviceShippingOffers,
        selectedRepairOffers,
        repairItems,
        parts,
        selectedShippingOffer,
    } = orderInfo;

    const { isFullDeviceRepair, deviceItems } = repairItems;

    const shippingOffer = Array.isArray(deviceShippingOffers) && deviceShippingOffers.find((offer: any) => offer && offer.symptomCode === symptomCode);

    if (!shippingOffer) {
        logException(CorrelationVector.getCurrentValue(), new Error(`Shipping offer for symptomCode ${symptomCode} not found`), {
            transformCheckoutPayload: {
                isFullDeviceRepair,
                symptomCode,
                deviceShippingOffers: JSON.stringify(deviceShippingOffers || {}).substring(0, 2048),
            },
        });
        throw new Error('shipping_offer_not_configured');
    }

    try {
        return (selectedRepairOffers[sku] || selectedRepairOffers[symptomCode]).isReplacementSerialNumberRequired &&!isWalkInCenterSelected(selectedShippingOffer)
            ? parts[deviceItems[sku].serialNumber || sku].serialNumber
            : null;
    } catch (e) {
        logException(CorrelationVector.getCurrentValue(), new Error('Could not find replacement SN for checkout'), {
            isFullDeviceRepair,
            sku,
            symptomCode,
            errorMessage: e.message,
            orderInfo: JSON.stringify({
                parts: orderInfo.parts,
                repairItems: orderInfo.repairItems,
                selectedShippingOffers: orderInfo.selectedShippingOffer,
            }).substring(0, 2048),
        });
        throw e;
    }
}

// If we have selected shipping offer for the device , we get it from the selectedShippingOffers, otherwise
// Else when we don't show shipping dropdown for the user, we take the first shipping offer from the shipping offers matching the offerSku
function getShippingOfferSku(shippingData: any) {
    const {
        offerId,
        selectedShippingOffers,
        deviceShippingOffers,
    } = shippingData;
    if (selectedShippingOffers && selectedShippingOffers[offerId]) {
        return selectedShippingOffers[offerId].offerSku;
    }
    const shippingOffers = deviceShippingOffers.find(
        (offer: any) => offer.offerSku === offerId
    ).offers;
    if (!shippingOffers || !shippingOffers.length) {
        throw new Error(`Internal Error: Shipping offer is not configured. Please contact CREW configuration support. Could not find ${offerId} in deviceShippingOffers`);
    }
    return shippingOffers[0].offerId;
}