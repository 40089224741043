import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Styles from './styles.module.scss';

interface Data {
    name: string;
    problemDescription?: string;
    problemType?: string;
    sku?: string;
    serialNumber?: string;
    numberOfRepairAttempts?: string;
    repairDetails?: string;
}

interface PropsType {
    data: Data[];
    title: string;
}

export default function RepairItemDetail(props: PropsType) {
    const { t } = useTranslation();
    const tableProps: any = {
        'aria-label': props.title,
        className: Styles.divTable,
        role: 'table',
    };
    const columnHeaderProps = {
        className: `${Styles.divTableCell} ${Styles.headerTitle}`,
        role: 'columnheader',
        scope: 'col',
    };

    const rowProps = {
        className: Styles.divTableRow,
        role: 'row',
    };

    const cellProps = {
        className: `${Styles.divTableCell} ${Styles.cellValue}`,
        role: 'gridcell',
    };

    // show sku column if at least one piece of data has sku available
    const showSku = showSkuColumn(props.data);

    return (
        <table className={Styles.tableInfo} {...tableProps}>
            <thead className={Styles.thead}>
                <tr {...rowProps}>
                    <th {...columnHeaderProps}>
                        {t('name')}
                    </th>
                    {showSku && <th {...columnHeaderProps}>
                        {t('SKU')}
                    </th>}
                    <th {...columnHeaderProps}>
                        {t('serial_number')}
                    </th>
                </tr>
            </thead>
            <tbody className={Styles.divTableBody}>
                {props.data.map((item: Data, key: number) => (
                    <React.Fragment key={key}>
                        <tr {...rowProps}>
                            <td {...cellProps}>
                                {item.name}
                            </td>
                            {showSku && <td {...cellProps}>
                                {item.sku || t('n/a')}
                            </td>}
                            <td {...cellProps}>
                                {item.serialNumber || t('n/a')}
                            </td>
                        </tr>
                        <ExtraInformationRow item={item} />
                        {key < props.data.length - 1 &&
                            <tr {...rowProps}>
                                <td className={`${Styles.divTableCell} ${Styles.lineSeparator} `} />
                                <td className={`${Styles.divTableCell} ${Styles.lineSeparator} `} />
                                <td className={`${Styles.divTableCell} ${Styles.lineSeparator} `} />
                            </tr>
                        }
                    </React.Fragment>
                ))}
            </tbody>
        </table>
    );
}

export function ExtraInformationRow ( { item } : any) {
    if (!item.problemType && !item.problemDescription) return null;
    const { t } = useTranslation();
    return (
        <tr className={Styles.divTableRow} role="row">
            <td colSpan={3} className={Styles.extraInformationContainer}>
                {item.problemType &&
                    <div className={Styles.informationContainer}>
                        <label className={Styles.label}>{t('problem_type')}:</label>
                        <span className={Styles.span}>{item.problemType}</span>
                    </div>
                }
                {item.problemDescription &&
                    <div className={Styles.informationContainer}>
                        <label className={Styles.label}>{t('problem_description')}:</label>
                        <span className={Styles.span}>{item.problemDescription}</span>
                    </div>
                }
                {item.numberOfRepairAttempts &&
                    <div className={Styles.informationContainer}>
                        <label className={Styles.label}>{t('number_of_repair_attempts')}:</label>
                        <span className={Styles.span}>{item.numberOfRepairAttempts}</span>
                    </div>
                }
                {item.repairDetails &&
                    <div className={Styles.informationContainer}>
                        <label className={Styles.label}>{t('repair_details')}:</label>
                        <span className={Styles.span}>{item.repairDetails}</span>
                    </div>
                }
            </td>
        </tr>
    );
}


function showSkuColumn (data: any[]) : boolean {
    return data.some(item => item.sku);
}