import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getSoldToList,
    GET_SOLD_TO_LIST,
    soldToListSelector,
    getShipToList,
    GET_SHIP_TO_LIST,
    shipToListSelector,
    createSoldToShipToStatusSelector,
    clearSoldToShipToMessage,
    POST_SOLD_TO_SHIP_TO,
    countryListSelector,
    postSoldToShipTo,
} from './../../store/managePartners';
import { useTranslation } from 'react-i18next';
import CommonLayout from './../../components/CommonLayout/CommonLayout';
import Styles from './managePartners.module.scss';
import clsx from 'clsx';
import BusySpinner from './../../components/BusySpinner';
import { SpinnerSize, ActionButton, TextField, Icon, TooltipHost } from 'office-ui-fabric-react';
import getColumnHeaders from './columnHeaders';
import { LoadingState, actionStatusSelector } from './../../store/actionStatus';
import { uniqBy } from 'lodash';
import { getCountriesData } from './../../store/countries';
import { getProvincesData } from './../../store/provinces';
import Toggler from './../../components/Toggler/Toggler';
import AddOrUpdate, { SoldToShipToType } from './AddOrUpdate';
import Message from './../../components/Messages';

const initialSoldToShipToState: SoldToShipToType = {
    process: 'ADD',
    soldToCustomerNbr: '',
    shipToCustomerNbr: '',
    customerName: '',
    countryCode: '',
    countryName: '',
    stateProvinceCode: '',
    stateProvinceName: '',
    addressLineOneText: '',
    addressLineTwoText: '',
    addressLineThreeText: '',
    cityName: '',
    postalCode: '',
    isDummy: false,
    isActive: false,
};

export default function ManagePartners() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [addNewRecord, setAddNewRecord] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState('');
    const [isSoldToAction, setIsSoldToAction] = React.useState(false);
    const [isShipToAction, setIsShipToAction] = React.useState(false);
    const [editing, setEditing] = React.useState(false);
    const [soldToShipTo, setSoldToShipTo] = React.useState<SoldToShipToType>(initialSoldToShipToState);

    const countries = useSelector((state: any) => countryListSelector(state));

    const soldToListStatus = useSelector(state => actionStatusSelector(state)(GET_SOLD_TO_LIST));
    const allSoldToList = useSelector(state => soldToListSelector(state));
    const shipToListStatus = useSelector(state => actionStatusSelector(state)(GET_SHIP_TO_LIST));
    const allShipToList = useSelector(state => shipToListSelector(state));
    const soldToShipToCreationResponse = useSelector(state => createSoldToShipToStatusSelector(state));
    const createStatus = useSelector(state => actionStatusSelector(state)(POST_SOLD_TO_SHIP_TO));

    const [soldToList, setSoldToList] = useState(Array(0));
    const [distinctSoldToList, setDistinctSoldToList] = useState(Array(0));
    const [expandedSoldTo, setExpandedSoldTo] = React.useState<string>('');
    const [searchText, setSearchText] = React.useState<string | undefined>('');

    const [pageNumber, setPageNumber] = React.useState<number>(1);
    const [pageSet, setPageSet] = React.useState<number>(10);
    const [totalRecordCount, setTotalRecordCount] = React.useState<number>(0);
    const [totalPages, setTotalPages] = React.useState<number>(0);

    const isLoading = (soldToListStatus.status === LoadingState.started
        || soldToListStatus.status === LoadingState.notStarted || shipToListStatus.status === LoadingState.started
        || shipToListStatus.status === LoadingState.notStarted || createStatus.status === LoadingState.started);

    const successMessage = soldToShipToCreationResponse.status
        && soldToShipToCreationResponse.status.toLowerCase() === 'succeeded'
        ? GetSuccessActionMessageText() : undefined;

    const errorMessage = soldToShipToCreationResponse.status
        && soldToShipToCreationResponse.status.toLowerCase() !== 'succeeded' ?
        `${t('error_occurred')} ${soldToShipToCreationResponse && soldToShipToCreationResponse.status}` : undefined;

    let renderPageNumbers;

    function capitalize(str: string) {
        return str !== undefined && str !== null && str !== '' ? str.charAt(0).toUpperCase() + str.slice(1) : '';
    }

    function GetSuccessActionMessageText() {
        if (editing && !addNewRecord) {
            return isSoldToAction ? t('soldto_updated_successfully') : (isShipToAction ? t('shipto_updated_successfully') : undefined);
        }
        return isSoldToAction ? t('soldto_created_successfully') : (isShipToAction ? t('shipto_created_successfully') : undefined);
    }

    function fetchCurrentShipToList(soldTo: string, searchingText?: string) {
        const shipToList: any = [];
        let filteredShipToList = allShipToList.filter((x: any) => x.soldToCustomerNbr === soldTo);
        let results = allSoldToList.filter((x: any) => x['soldToCustomerNbr'].toLowerCase().indexOf(searchingText?.toLowerCase()) >= 0);
        results = results.concat(allSoldToList.filter((x: any) => x['customerName'].toLowerCase().indexOf(searchingText?.toLowerCase()) >= 0));
        if (results.length <= 0 && searchingText !== '') {
            filteredShipToList = filteredShipToList.filter((x: any) => x['shipToCustomerNbr'].toLowerCase().indexOf(searchingText?.toLowerCase()) >= 0);
            filteredShipToList.concat(filteredShipToList.filter((x: any) => x['customerName'].toLowerCase().indexOf(searchingText?.toLowerCase()) >= 0));
        }
        if (filteredShipToList.length <= 0) {
            filteredShipToList = allShipToList.filter((x: any) => x.soldToCustomerNbr === soldTo);
        }
        filteredShipToList.forEach((element: any) => {
            shipToList.push(
                {
                    soldToCustomerNbr: '',
                    shipToCustomerNbr: element.shipToCustomerNbr,
                    email: element.email,
                    customerName: element.customerName,
                    countryCode: element.countryCode,
                    countryName: element.countryName,
                    stateProvinceCode: element.stateProvinceCode,
                    stateProvinceName: element.stateProvinceName,
                    addressLineOneText: element.addressLineOneText,
                    addressLineTwoText: element.addressLineTwoText,
                    addressLineThreeText: element.addressLineThreeText,
                    cityName: element.cityName,
                    postalCode: element.postalCode,
                    isDummy: element.isDummy,
                    isActive: element.isActive,
                    selectedSoldTo: soldTo,
                }
            );
        });
        return shipToList;
    }

    function GetFilteredResults(searchingText?: string) {
        if (searchingText && searchingText !== '') {
            let soldToNbrResults: any[] = [];
            soldToNbrResults = allSoldToList.filter((x: any) => x['soldToCustomerNbr'].toLowerCase().indexOf(searchingText?.toLowerCase()) >= 0);
            const nameResults = allSoldToList.filter((x: any) => x['customerName'].toLowerCase().indexOf(searchingText?.toLowerCase()) >= 0);
            let results = soldToNbrResults.concat(nameResults);
            results = [...new Set([...soldToNbrResults, ...nameResults])];
            //results = results.filter((item, pos) => results.indexOf(item) === pos);
            if (results.length <= 0) {
                let filteredShipTo = allShipToList.filter((x: any) => x['shipToCustomerNbr'].toLowerCase().indexOf(searchingText?.toLowerCase()) >= 0);
                filteredShipTo = filteredShipTo.concat(allShipToList.filter((x: any) => x['customerName'].toLowerCase().indexOf(searchingText?.toLowerCase()) >= 0));
                for (let index = 0; index < filteredShipTo.length; index++) {
                    const soldToNbr = filteredShipTo[index]['soldToCustomerNbr'] !== null ? filteredShipTo[index]['soldToCustomerNbr'].toLowerCase() : '';
                    if (results.length <= 0 || (soldToNbr !== '' && results.filter((y: any) => y['soldToCustomerNbr'].toLowerCase() === soldToNbr).length <= 0)) {
                        //const filteredSoldTos = ;
                        results = results.concat(allSoldToList.filter((x: any) => x['soldToCustomerNbr'].toLowerCase() === soldToNbr));
                    }
                }
            }
            if (results.length === 1) {
                setExpandedSoldTo(results[0].soldToCustomerNbr);
            }
            else {
                setExpandedSoldTo('');
            }
            return results;
        }
        return uniqBy(allSoldToList, (item: any) => item.soldToCustomerNbr);
    }

    function fetchCurrentSoldToList(currPageNum: number, searchData?: string) {
        setSoldToList(Array(0));
        while (soldToList.length) {
            soldToList.pop();
        }
        let filteredSoldToList: any = [];
        if (searchData !== '' || searchText !== '') {
            const targetSearchText = searchData !== '' ? searchData : searchText;
            filteredSoldToList = GetFilteredResults(targetSearchText);
        }
        else {
            filteredSoldToList = uniqBy(allSoldToList, (item: any) => item.soldToCustomerNbr);
        }
        setDistinctSoldToList(filteredSoldToList);
        const startFrom = ((currPageNum - 1) * pageSet);
        const endTo = startFrom + pageSet;
        const totalCounts = filteredSoldToList.length;
        const totalPages = (totalCounts % pageSet) > 0 ? parseInt(((totalCounts / pageSet) + 1).toString(), 10) : totalCounts / pageSet;
        setTotalRecordCount(totalCounts);
        setTotalPages(totalPages);

        for (let index = 0; index < filteredSoldToList.length; index++) {
            if (index >= startFrom && index < endTo) {
                const element = filteredSoldToList[index];
                soldToList.push(
                    {
                        soldToCustomerNbr: element.soldToCustomerNbr,
                        shipToCustomerNbr: '',
                        email: element.email,
                        customerName: element.customerName,
                        countryCode: element.countryCode,
                        countryName: element.countryName,
                        stateProvinceCode: element.stateProvinceCode,
                        stateProvinceName: element.stateProvinceName,
                        addressLineOneText: element.addressLineOneText,
                        addressLineTwoText: element.addressLineTwoText,
                        addressLineThreeText: element.addressLineThreeText,
                        cityName: element.cityName,
                        postalCode: element.postalCode,
                        isDummy: element.isDummy,
                        isActive: element.isActive,
                        shipToList: fetchCurrentShipToList(element.soldToCustomerNbr, searchData !== '' ? searchData : searchText),
                    }
                );
            }
        }
        setSoldToList(soldToList);
    }

    function handleOnClick(event: any) {
        let targetValue: number = 0;
        const pageData = event.currentTarget.dataset.arg;
        if (pageData.toLowerCase() === 'previous') {
            targetValue = pageNumber - 1;
        }
        else if (pageData.toLowerCase() === 'next') {
            targetValue = pageNumber + 1;
        }
        else {
            targetValue = parseInt(event.currentTarget.dataset.arg!, 10);
        }
        setPageNumber(targetValue);
        fetchCurrentSoldToList(targetValue, '');
    }

    function onCloseOrOpen(show: boolean) {
        setAddNewRecord(show);
    }

    React.useEffect(() => {
        dispatch(getShipToList());
        dispatch(getSoldToList());
        dispatch(getCountriesData());
        dispatch(getProvincesData());
    }, []);

    React.useEffect(() => {
        setPageSet(10);
        fetchCurrentSoldToList(pageNumber, '');
    }, [allSoldToList, allShipToList]);

    const pageNumbersList = [];
    if (totalRecordCount !== null) {
        const totalPagesCount = (totalRecordCount % pageSet) > 0 ? (totalRecordCount / pageSet) + 1 : totalRecordCount / pageSet;
        for (let i = 1; i <= totalPagesCount; i++) {
            pageNumbersList.push(i);
        }
        renderPageNumbers = pageNumbersList.map(number => {
            const activeClass = pageNumber === number ? Styles.active : '';
            const visibleClass = (number >= pageNumber - 2 && number <= pageNumber + 2) ? '' : (number >= pageNumber - 2 && number <= 5 ? '' : Styles.hidden);
            const labelName = `Page ${number}`;
            return (
                <div tabIndex={0}
                    role="button"
                    aria-label={labelName}
                    key={number}
                    className={clsx(activeClass, visibleClass)}
                    data-arg={number}
                    onClick={handleOnClick}>{number}</div>
            );
        });
    }

    function loadShipToList(event: any) {
        const soldTo = event.currentTarget.dataset.arg;
        setExpandedSoldTo(soldTo);
    }

    function onChangeSearchText(event: any, newValue?: string) {
        setSearchText(newValue);
        fetchCurrentSoldToList(1, newValue);
    }

    function onClickAddNewSoldTo(event: any) {
        setIsSoldToAction(true);
        setIsShipToAction(false);
        setDialogTitle(t('add_new_soldto'));
        dispatch(clearSoldToShipToMessage());
        setEditing(false);
        setSoldToShipTo({ ...initialSoldToShipToState, ['process']: 'ADD' });
        setAddNewRecord(true);
    }

    function onClickAddNewShipTo(event: any) {
        setIsSoldToAction(false);
        setIsShipToAction(true);
        setDialogTitle(t('add_new_shipto'));
        dispatch(clearSoldToShipToMessage());
        setEditing(false);
        setSoldToShipTo({ ...initialSoldToShipToState, ['process']: 'ADD' });
        setAddNewRecord(true);
    }

    function handleEditClick(event: any) {
        const args = JSON.parse(event.currentTarget.dataset.arg);
        setDialogTitle(t('edit'));
        dispatch(clearSoldToShipToMessage());
        setEditing(true);
        setSoldToShipTo({ ...args, ['process']: 'UPDATE' });
        if (args['shipToCustomerNbr'] && args['shipToCustomerNbr'].trim() !== '') {
            setSoldToShipTo((prevState: any) => ({ ...prevState, soldToCustomerNbr: args['selectedSoldTo'] }));
            setIsSoldToAction(false);
            setIsShipToAction(true);
            setDialogTitle(t('edit_shipto'));
        }
        else {
            setIsSoldToAction(true);
            setIsShipToAction(false);
            setDialogTitle(t('edit_soldto'));
        }
        setAddNewRecord(true);
    }

    function processToggle(active: boolean, data: SoldToShipToType, toggleField: string) {
        dispatch(clearSoldToShipToMessage());
        setEditing(true);
        const editSoldToShipTo: SoldToShipToType = {
            process: 'UPDATE',
            soldToCustomerNbr: data['selectedSoldTo'] && data['selectedSoldTo'] !== '' ? data['selectedSoldTo'] : data.soldToCustomerNbr,
            shipToCustomerNbr: data.shipToCustomerNbr,
            customerName: data.customerName,
            countryCode: data.countryCode,
            countryName: data.countryName,
            stateProvinceCode: data.stateProvinceCode,
            stateProvinceName: data.stateProvinceName,
            addressLineOneText: data.addressLineOneText,
            addressLineTwoText: data.addressLineTwoText,
            addressLineThreeText: data.addressLineThreeText,
            cityName: data.cityName,
            postalCode: data.postalCode,
            isDummy: toggleField === 'isDummy' ? active : data.isDummy,
            isActive: toggleField === 'isActive' ? active : data.isActive,
        };
        dispatch(postSoldToShipTo({ soldToShipToDetails: editSoldToShipTo }));
        dispatch(getShipToList());
        dispatch(getSoldToList());
        if (data.shipToCustomerNbr && data.shipToCustomerNbr !== '') {
            setIsSoldToAction(false);
            setIsShipToAction(true);
        }
        else {
            setIsShipToAction(false);
            setIsSoldToAction(true);
        }
    }

    function handleIsDummyToggle(active: boolean, data: SoldToShipToType) {
        processToggle(active, data, 'isDummy');
    }

    function handleIsActiveToggle(active: boolean, data: SoldToShipToType) {
        processToggle(active, data, 'isActive');
    }

    function onSoldToShipToSaveClicked(soldToShipToInputs: SoldToShipToType) {
        dispatch(postSoldToShipTo({ soldToShipToDetails: soldToShipToInputs }));
        dispatch(getShipToList());
        dispatch(getSoldToList());
    }

    function getFormattedColumn(index: number, column: any, element: any) {
        return <td key={index} role="gridcell" className={column.visible ? '' : Styles.hidden} style={{ width: column.width }}>
            <div style={{ width: column.width }}>{element[column.fieldName]}</div>
        </td>;
    }

    return (
        <CommonLayout pageTitle={[t('manage_partners')]}>
            <section className={Styles.container}>
                <h1 className={Styles.header}>{t('manage_partners')}</h1>
                {
                    isLoading ?
                        (<div className={clsx('ms-Grid', Styles.loading)}>
                            <div className="ms-Grid-row">
                                <div className={clsx('ms-Grid-col')}>
                                    <BusySpinner
                                        className={Styles.spinner}
                                        messageKey={t('loading')}
                                        size={SpinnerSize.large}
                                    />
                                </div>
                            </div>
                        </div>)
                        : (
                            <div className={clsx('ms-Grid', Styles.grid)} style={{ padding: 0 }}>
                                <div className="ms-Grid-row" style={{ margin: 0 }}>
                                    <div className={clsx('ms-Grid-col')} style={{ padding: 10, paddingBottom: 0 }}>
                                        <AddOrUpdate
                                            hide={!addNewRecord}
                                            onCloseOrOpen={onCloseOrOpen}
                                            soldToShipTo={soldToShipTo}
                                            title={dialogTitle}
                                            soldToList={distinctSoldToList}
                                            countriesList={countries}
                                            isSoldToAction={isSoldToAction}
                                            isShipToAction={isShipToAction}
                                            onSaveClicked={onSoldToShipToSaveClicked}
                                        />
                                        <Message successMessage={successMessage} errorMessage={errorMessage} />
                                    </div>
                                </div>
                                <div className="ms-Grid-row" style={{ padding: 15, margin: 0, paddingBottom: 0 }}>
                                    <div className={clsx('ms-Grid-col', 'ms-sm12', Styles.toolbarBlock)}>
                                        <div className={Styles.dvSearchBlock}>
                                            <Icon iconName="Filter" />
                                            <span>
                                                <TextField
                                                    ariaLabel={t('searchText')}
                                                    id="txt-search-text"
                                                    name="searchText"
                                                    onChange={onChangeSearchText}
                                                    placeholder={t('filter_by_keyword')}
                                                    value={searchText} />
                                            </span>
                                        </div>
                                        <div className={Styles.dvAddNewBlock}>
                                            <ActionButton
                                                className={Styles.addNewButton}
                                                iconProps={{ iconName: 'Save' }}
                                                ariaLabel={t('add_new_soldto')}
                                                text={t('add_new_soldto')}
                                                onClick={onClickAddNewSoldTo} />
                                            <ActionButton
                                                className={Styles.addNewButton}
                                                iconProps={{ iconName: 'Save' }}
                                                ariaLabel={t('add_new_shipto')}
                                                text={t('add_new_shipto')}
                                                onClick={onClickAddNewShipTo} />
                                        </div>
                                    </div>
                                </div>
                                <div className="ms-Grid-row" style={{ padding: 15, margin: 0 }}>
                                    <div className={clsx('ms-Grid-col', 'ms-sm12', Styles.gridParent)} style={{ padding: 0 }}>
                                        <table className={Styles.tableNonBordered} role="grid">
                                            <thead>
                                                <tr role="row">
                                                    <th role="gridcell" style={{ width: 34 }}>
                                                        <div style={{ width: 34 }} />
                                                    </th>
                                                    {getColumnHeaders(t).map((column: any, index: number) => (
                                                        <th key={index} role="gridcell" className={column.visible ? '' : Styles.hidden} style={{ width: column.width }}>
                                                            <div style={{ width: column.width }}>{capitalize(column.name)}</div>
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr role="row" className={soldToList.length > 0 ? Styles.hidden : ''}>
                                                    <td role="gridcell" className={Styles.tdNoRecordFound}>
                                                        {t('no_records_found')}
                                                    </td>
                                                </tr>
                                                {soldToList.map((element: any, index: number) => (
                                                    <tr key={index} role="row">
                                                        <td role="gridcell" className={expandedSoldTo !== element.soldToCustomerNbr ? '' : Styles.hidden} style={{ width: 34 }}>
                                                            <div aria-label={`${t('Expand')} ${element.soldToCustomerNbr}`} className={element.shipToList.length > 0 ? '' : Styles.hidden}>
                                                                <ActionButton
                                                                    ariaLabel={t('expand')}
                                                                    iconProps={{
                                                                        ariaLabel: t('expand'),
                                                                        iconName: 'Add',
                                                                        style: { fontSize: 12, fontWeight: 700 },
                                                                    }}
                                                                    onClick={loadShipToList}
                                                                    data-arg={element.soldToCustomerNbr}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td role="gridcell" className={expandedSoldTo === element.soldToCustomerNbr ? '' : Styles.hidden} style={{ width: 34 }}>
                                                            <div aria-label={`${t('collapse')} ${element.soldToCustomerNbr}`} >
                                                                <ActionButton
                                                                    ariaLabel={t('collapse')}
                                                                    iconProps={{
                                                                        ariaLabel: t('collapse'),
                                                                        iconName: 'Remove',
                                                                        style: { fontSize: 12, fontWeight: 700 },
                                                                    }}
                                                                    onClick={loadShipToList}
                                                                    data-arg={''}
                                                                />
                                                            </div>
                                                        </td>
                                                        {getColumnHeaders(t).map((column: any, innerIndex: number) => {
                                                            if (
                                                                column.fieldName === 'edit' || column.fieldName === 'isActive' ||
                                                                column.fieldName === 'isDummy'
                                                                ) {
                                                                if (column.fieldName === 'edit') {
                                                                    return <td key={innerIndex} role="gridcell" style={{ width: column.width }}>
                                                                        <div className={Styles.actionContainer}>
                                                                            <TooltipHost
                                                                                hostClassName={Styles.modalToolTip}
                                                                                aria-label={t('edit')}
                                                                                content={t('edit')}>
                                                                                <ActionButton
                                                                                    ariaLabel={t('edit')}
                                                                                    iconProps={{
                                                                                        ariaLabel: t('edit'),
                                                                                        iconName: 'Edit',
                                                                                    }}
                                                                                    onClick={handleEditClick}
                                                                                    data-arg={JSON.stringify(element)}
                                                                                /></TooltipHost>
                                                                        </div>
                                                                    </td>;
                                                                }
                                                                if (column.fieldName === 'isActive') {
                                                                    return <td key={innerIndex} role="gridcell" style={{ width: column.width }}>
                                                                        <div className={Styles.actionContainer}>
                                                                            <Toggler
                                                                                isOn={element.isActive}
                                                                                ariaLabel={element.isActive ? t('activate') : t('deactivate')}
                                                                                handleToggle={handleIsActiveToggle}
                                                                                data={element}
                                                                                on={t('active')}
                                                                                off={t('inactive')}
                                                                            />
                                                                        </div>
                                                                    </td>;
                                                                }
                                                                return <td key={innerIndex} role="gridcell" style={{ width: column.width }}>
                                                                            <div className={Styles.actionContainer}>
                                                                                <Toggler
                                                                                    id="ssDummyToggle"
                                                                                    isOn={element.isDummy}
                                                                                    ariaLabel={element.isDummy ? t('activate') : t('deactivate')}
                                                                                    handleToggle={handleIsDummyToggle}
                                                                                    data={element}
                                                                                    on={t('isdummy_yes')}
                                                                                    off={t('isdummy_no')}
                                                                                />
                                                                            </div>
                                                                        </td>;
                                                            }
                                                            { return getFormattedColumn(innerIndex, column, element); }
                                                        })}
                                                        <td role="gridcell" className={expandedSoldTo === element.soldToCustomerNbr ? Styles.displayBlock : Styles.hidden}>
                                                            <table className={Styles.tableNonBordered} role="grid">
                                                                <tbody>
                                                                    {element.shipToList.map((childElement: any, childIndex: number) => (
                                                                        <tr key={childIndex} role="row" style={{ overflow: 'hidden', padding: 0 }}>
                                                                            <td role="gridcell" style={{ width: 34 }}>
                                                                                <div />
                                                                            </td>
                                                                            {getColumnHeaders(t).map((column: any, innerIndex: number) => {
                                                                                if (
                                                                                    column.fieldName === 'edit' || column.fieldName === 'isActive' ||
                                                                                    column.fieldName === 'isDummy'
                                                                                    ) {
                                                                                    if (column.fieldName === 'edit') {
                                                                                        return <td key={innerIndex} role="gridcell" style={{ width: column.width }}>
                                                                                            <div className={Styles.actionContainer}>
                                                                                                <TooltipHost
                                                                                                    hostClassName={Styles.modalToolTip}
                                                                                                    aria-label={t('Edit')}
                                                                                                    content={t('Edit')}>
                                                                                                    <ActionButton
                                                                                                        ariaLabel={t('edit')}
                                                                                                        iconProps={{
                                                                                                            ariaLabel: t('edit'),
                                                                                                            iconName: 'Edit',
                                                                                                        }}
                                                                                                        onClick={handleEditClick}
                                                                                                        data-arg={JSON.stringify(childElement)}
                                                                                                    />
                                                                                                </TooltipHost>
                                                                                            </div>
                                                                                        </td>;
                                                                                    }
                                                                                    if (column.fieldName === 'isActive') {
                                                                                        return <td key={innerIndex} role="gridcell" style={{ width: column.width }}>
                                                                                            <div className={Styles.actionContainer}>
                                                                                                <Toggler
                                                                                                    isOn={childElement.isActive}
                                                                                                    ariaLabel={childElement.isActive ? t('activate') : t('deactivate')}
                                                                                                    handleToggle={handleIsActiveToggle}
                                                                                                    data={childElement}
                                                                                                    on={t('active')}
                                                                                                    off={t('inactive')}
                                                                                                />
                                                                                            </div>
                                                                                        </td>;
                                                                                    }
                                                                                    return <td key={innerIndex} role="gridcell" style={{ width: column.width }}>
                                                                                        <div className={Styles.actionContainer}>
                                                                                            <Toggler
                                                                                                isOn={childElement.isDummy}
                                                                                                ariaLabel={childElement.isDummy ? t('activate') : t('deactivate')}
                                                                                                handleToggle={handleIsDummyToggle}
                                                                                                data={childElement}
                                                                                                on={t('isdummy_yes')}
                                                                                                off={t('isdummy_no')} />
                                                                                        </div>
                                                                                    </td>;
                                                                                }
                                                                                { return getFormattedColumn(innerIndex, column, childElement); }
                                                                            })}
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className={Styles.pagination}>
                                        <div>
                                            <ActionButton
                                                ariaLabel={t('FirstPage')}
                                                iconProps={{
                                                    ariaLabel: t('FirstPage'),
                                                    iconName: 'Previous',
                                                }}
                                                data-arg={'1'}
                                                onClick={handleOnClick}
                                                disabled={pageNumber === 1}
                                            />
                                        </div>
                                        <div>
                                            <ActionButton
                                                ariaLabel={t('PrevPage')}
                                                iconProps={{
                                                    ariaLabel: t('PrevPage'),
                                                    iconName: 'CaretSolidLeft',
                                                }}
                                                data-arg={'Previous'}
                                                onClick={handleOnClick}
                                                disabled={pageNumber === 1}
                                            />
                                        </div>
                                        {renderPageNumbers}
                                        <div>
                                            <ActionButton
                                                ariaLabel={t('NextPage')}
                                                iconProps={{
                                                    ariaLabel: t('NextPage'),
                                                    iconName: 'CaretSolidRight',
                                                }}
                                                data-arg={'Next'}
                                                onClick={handleOnClick}
                                                disabled={totalPages === 1 || pageNumber === totalPages}
                                            />
                                        </div>
                                        <div>
                                            <ActionButton
                                                ariaLabel={t('LastPage')}
                                                iconProps={{
                                                    ariaLabel: t('LastPage'),
                                                    iconName: 'Next',
                                                }}
                                                data-arg={totalPages}
                                                onClick={handleOnClick}
                                                disabled={totalPages === 1 || pageNumber === totalPages}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                }
            </section>
        </CommonLayout >
    );

}