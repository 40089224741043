import {  call, put } from 'redux-saga/effects';

import DeviceDal from './../../../dal/DeviceDal';
import {
    actionStatusStarted,
    actionStatusFailed,
    actionStatusSuccess,
} from './../../../store/actionStatus';

import {
    getClaimDetailsDataFail,
    getClaimDetailsDataSuccess,
    GET_CLAIM_DETAILS,
} from './../../../store/deviceInfo/deviceInfo';

export function* fetchClaimDetails (request: any): IterableIterator<any>  {
    const {
        serialNumber,
    } = request.payload;
    yield put(actionStatusStarted({ actionName: GET_CLAIM_DETAILS }));
    try {
        yield put(actionStatusStarted({ actionName: GET_CLAIM_DETAILS }));
        const claimDet: any = yield call(DeviceDal.getWarrantyClaimDetails, serialNumber);
        yield put(getClaimDetailsDataSuccess(claimDet));
        yield put(actionStatusSuccess({ actionName: GET_CLAIM_DETAILS }));
    } catch (e) {
        yield put(actionStatusFailed({
            actionName: GET_CLAIM_DETAILS,
            data: { serialNumber },
            error: e,
        }));
        yield put(getClaimDetailsDataFail(e));
    }
}