import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import * as XLSX from 'xlsx';
import { Icon } from 'office-ui-fabric-react';

import CommonLayout from './../../components/CommonLayout';
import { validateEmail } from './../../helpers/validation';
import Styles from './manageBulkUsers.module.scss';
import { userInfoSelector } from './../../store/user';
import {
    getAllUsers,
    getChannels,
    getRoles,
    getSoldToShipTo,
    getTiers,
    usersSelector,
    channelsSelector,
    rolesSelector,
    soldToShipToSelector,
    allTierSelector,
} from './../../store/manageUsers';
import { clearBulkUsersMessage } from './../../store/manageAadOps';
import { RetailUserType } from './../../views/manageUsers/AddOrUpdate';
import getColumnHeaders from './../../components/ManageBulkUsersModal/columnHeaders';
import ManageBulkUsersModal from './../../components/ManageBulkUsersModal/ManageBulkUsersModal';

export interface BulkRetailUserType {
    retailUser: RetailUserType;
    status: string;
    action: string;
    errorInfo: string;
    rowIndex: number;
}

export default function ManageBulkUsers() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const downloadFilePath = require('./../../assets/samples/ManageUsersTemplate.xlsx');
    const downloadFileName = 'BulkUsersUploadTemplate.xlsx';

    const headerSchemaObject = getColumnHeaders(t).filter((x: any) => x.fieldName !== 'status');
    const headerSchemaData = headerSchemaObject.map((x: any) => x.name);

    const [fileInputKey, setFileInputKey] = useState('fileOn');
    const [validationMsg, setValidationMsg] = useState('');
    const [uploadedFile, setUploadedFile] = useState(null);
    const [bulkRetailUsersList, setBulkRetailUsersList] = useState<BulkRetailUserType[]>([]);

    const loggedInUserInfo = useSelector(state => userInfoSelector(state));
    const allUsers = useSelector(state => usersSelector(state));
    const rolesList = useSelector(state => rolesSelector(state));
    const channelsList = useSelector(state => channelsSelector(state));
    const tiersList = useSelector(state => allTierSelector(state));
    const soldToShipToList = useSelector(state => soldToShipToSelector(state));

    React.useEffect(() => {
        dispatch(getAllUsers());
        dispatch(getSoldToShipTo());
        dispatch(getRoles());
        dispatch(getChannels());
        dispatch(getTiers({ channel: '' }));
    }, []);

    const uploadFileClick = (event: any) => {
        event.target.value = null;
    };

    const clearFileInput = () => {
        setFileInputKey('fileOff');
        setUploadedFile(null);
    };

    const checkSoldToShipToComobo = (soldToId: any, shipToId: any) => {
        const shipToDetails = soldToShipToList.filter((shipTo: any) => shipTo.shiptoCustomerNbr?.toLowerCase() === shipToId.toLowerCase());
        if (shipToDetails[0].soldtoCustomerNbr?.toLowerCase() === soldToId.toLowerCase()) {
            return true;
        }
        return false;
    };

    const validateErrors = (userAction: any, userInfo: RetailUserType) => {
        let errorText = '';
        if (userAction === '') {
            errorText = t('validation_empty_user_action');
        }
        else if (userAction.toLowerCase() !== 'update' && userAction.toLowerCase() !== 'status' && userInfo.email && userInfo.email.toLowerCase() === loggedInUserInfo.email.toLowerCase()) {
            errorText = t('validation_invalid_user_action');
        }
        else if (userAction.toLowerCase() !== 'status') {
            if (userInfo.firstName === '') {
                errorText = t('validation_msg_firstName');
            }
            if (userInfo.lastName === '') {
                errorText += `, ${t('validation_msg_lastName')}`;
            }
            if (userInfo.email === '' || !validateEmail(userInfo.email)) {
                errorText += `, ${t('invalid_email_address')}`;
            }
            if (userInfo.roleName === '' || !rolesList.some((role: any) => role.roleName.toLowerCase() === userInfo.roleName?.toLowerCase())) {
                errorText += `, ${t('validation_msg_role')}`;
            }
            if (userInfo.channelName === '' || !channelsList.some((channel: any) => channel.channelName?.toLowerCase() === userInfo.channelName?.toLowerCase())) {
                errorText += `, ${t('validation_msg_channel')}`;
            }
            if (userInfo.tierName === '' || !tiersList.some((tier: any) => tier.tierName === userInfo.tierName)) {
                errorText += `, ${t('validation_msg_tier')}`;
            }
            if (userInfo.soldToCustomerNbr === '' || !soldToShipToList.some((soldTo: any) => soldTo.soldtoCustomerNbr?.toLowerCase() === userInfo.soldToCustomerNbr?.toString().toLowerCase())) {
                errorText += `, ${t('validation_msg_soldToCustomer')}`;
            }
            if (userInfo.shiptoCustomerNbr === '' || !soldToShipToList.some((shipTo: any) => shipTo.shiptoCustomerNbr?.toLowerCase() === userInfo.shiptoCustomerNbr?.toString().toLowerCase())) {
                errorText += `, ${t('validation_msg_shipToCustomer')}`;
            }
            else if (!checkSoldToShipToComobo(userInfo.soldToCustomerNbr?.toString().toLowerCase(), userInfo.shiptoCustomerNbr?.toString().toLowerCase())) {
                errorText += `, ${t('validation_msg_invalidsoldtoshipto_combo')}`;
            }
        }
        else {
            if (userInfo.email === '' || !validateEmail(userInfo.email)) {
                errorText += `, ${t('invalid_email_address')}`;
            }
        }
        return errorText.startsWith(', ') ? errorText.substring(2) : errorText;
    };

    const getExcelHeaderRowData = (headerRow: any) => {
        const tempArray = [];
        for (const i of headerRow) {
            i && i !== null && i.trim() !== '' && tempArray.push(i);
        }
        return tempArray;
    };

    const getFileContents = (bufferData: any) => {
        const wb = XLSX.read(bufferData, { type: 'buffer' });
        const workSheet = wb.Sheets['Inputs'];
        if (workSheet === undefined) {
            setValidationMsg(t('worksheet_invalid_data'));
            setUploadedFile(null);
            return;
        }
        const excelDatalist = XLSX.utils.sheet_to_json(workSheet, { header: 1 });
        const excelDatalistHeader = getExcelHeaderRowData(excelDatalist[0] as any);

        if (excelDatalistHeader?.length !== headerSchemaData.length || JSON.stringify(excelDatalistHeader) !== JSON.stringify(headerSchemaData)) {
            setValidationMsg(t('worksheet_invalid_schema'));
            setUploadedFile(null);
            return;
        }
        return excelDatalist;
    };

    const uploadFileContent = (event: any) => {
        if (event.target.files.length <= 0) {
            return;
        }
        setUploadedFile(event.target.files[0]);
        setValidationMsg('');
        setBulkRetailUsersList([]);
        dispatch(clearBulkUsersMessage());

        const reader = new FileReader();
        reader.readAsArrayBuffer(event.target.files[0]);
        try {
            reader.onload = (event: any) => {
                const bufferArray = event.target.result;
                let excelDataSet: any = getFileContents(bufferArray);
                const excelRowsCount = excelDataSet?.filter((x: any) => x.length > 0).length;
                if (excelRowsCount > 0 && excelRowsCount <= 50) {
                    excelDataSet = excelDataSet.slice(1);
                    const bulkUsersDataList: BulkRetailUserType[] = [];
                    let currIndex: number = 0;
                    excelDataSet.forEach((userData: any) => {
                        if (userData.length > 0) {
                            const retailUser: any = {
                                createdEmailId: loggedInUserInfo.email,
                                firstName: userData[0] ? userData[0].toString().trim() : '',
                                lastName: userData[1] ? userData[1].toString().trim() : '',
                                email: userData[2] ? userData[2].toString().trim() : '',
                                roleName: userData[3] ? userData[3].toString().trim() : '',
                                channelName: userData[4] ? userData[4].toString().trim() : '',
                                tierName: userData[5] ? userData[5].toString().trim() : '',
                                soldToCustomerNbr: userData[6] ? userData[6].toString().trim() : '',
                                shiptoCustomerNbr: userData[7] ? userData[7].toString().trim() : '',
                                isWarrantyUpdateEnabled: (userData[8] && userData[8].toString().trim() !== '') ? userData[8].toString().trim().toLowerCase() === 'yes' ? true : false : 'No',
                                isActive: true,
                                inviteRedeemUrl: '',
                                aadUserId: '',
                                channelId: '',
                                retailUserId: 0,
                                roleId: '',
                            };
                            if (retailUser.email !== '') {
                                const bulkUserData: any = {};
                                bulkUserData.retailUser = retailUser;
                                bulkUserData.action = userData[9] ? userData[9].toString().trim() : '';
                                bulkUserData.errorInfo = validateErrors(bulkUserData.action, retailUser);
                                bulkUserData.status = '';
                                bulkUserData.rowIndex = currIndex;
                                if (bulkUserData.action.toLowerCase() === 'update') {
                                    const currUserObj = allUsers.filter((x: any) => x.email.toLowerCase() === bulkUserData.retailUser.email.toLowerCase());
                                    bulkUserData.retailUser.retailUserId = currUserObj && currUserObj.length > 0 ? currUserObj[0].retailUserId : 0;
                                }

                                bulkUsersDataList.push(bulkUserData);
                                currIndex++;
                            }
                        }
                    });
                    setBulkRetailUsersList(bulkUsersDataList);
                }
                else {
                    if (excelDataSet?.length > 50) {
                        setValidationMsg(t('worksheet_over_max_data'));
                        setUploadedFile(null);
                    }
                    else if (excelDataSet?.length <= 0) {
                        setValidationMsg(t('worksheet_empty_data'));
                        setUploadedFile(null);
                    }
                    return;
                }
            };
        } catch (ex) {
            setValidationMsg(t('worksheet_invalid_data'));
            setUploadedFile(null);
            return;
        }
    };

    return (
        <CommonLayout pageTitle={[t('manage_bulk_users')]}>
            <section className={Styles.container}>
                <h2 className={Styles.header}>{t('manage_bulk_users')}</h2>
                {
                    <div className={clsx('ms-Grid', Styles.grid)}>
                        <div className={clsx('ms-Grid-row', Styles.gridMainRow)}>
                            <div className="ms-Grid-row">
                                <p className={Styles.rowHeader}>{t('manage_bulk_users_content_header')}</p>
                                {t('manage_bulk_users_content_line1')} <br />
                                {t('manage_bulk_users_content_line2')}<br />
                                {t('manage_bulk_users_content_line3')} <br />
                                {t('manage_bulk_users_content_line4')}<br />
                                {t('manage_bulk_users_content_line5')}<br />
                            </div>
                            <div className="ms-Grid-row" style={{ padding: '60px 0px 60px 0px' }}>
                                <div className={Styles.divLeft} style={{ lineHeight: '42px' }}>
                                    {[t('manage_bulk_users_content_line6')]}
                                </div>
                                <div className={Styles.divRight}>
                                    <a href={downloadFilePath} className={Styles.ancDownloadButton} download={downloadFileName}>
                                        <button className={Styles.downloadButton} type="button">Download</button>
                                    </a>
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className={Styles.divLeft}>
                                    <input className={Styles.inpUpload} type="file" key={fileInputKey} onClick={uploadFileClick} onChange={uploadFileContent} />
                                    {uploadedFile ?
                                        <a href="#" role="button" className={Styles.modalCloseIcon} aria-label="Clear File Input" onClick={clearFileInput}>
                                            <Icon iconName="Cancel" />
                                        </a> : ''
                                    }
                                </div>
                                <div className={Styles.divRight}>
                                    {uploadedFile && bulkRetailUsersList && bulkRetailUsersList.length > 0 ?
                                        <span className={uploadedFile === undefined && uploadedFile === null ? Styles.hidden : ''}>
                                            <ManageBulkUsersModal bulkRetailUsersList={bulkRetailUsersList} />
                                        </span> : ''
                                    }
                                </div>
                            </div>
                            {validationMsg !== '' ? <div className={Styles.validationMsg}>{validationMsg}</div> : null}
                        </div>
                    </div>
                }
            </section>
        </CommonLayout >
    );
}