import { put, call, takeEvery } from 'redux-saga/effects';
import i18n from '../../../lib/i18n';
import GraphApiDal from './../../../dal/GraphApiDal';
// import Environment from 'Environments';
import {
    actionStatusStarted,
    actionStatusFailed,
    actionStatusSuccess,
} from './../../../store/actionStatus';
import {
    POST_ADD_AAD_USER,
    POST_ADD_BULK_AAD_USERS,
    postAddAadUserSuccess,
    postAddBulkAadUsersSuccess,
    CLEAR_AAD_USER_MESSAGE,
    CLEAR_BULK_USERS_MESSAGE,
} from './../../../store/manageAadOps';
import { BulkRetailUserType } from './../../../views/manageBulkUsers/manageBulkUsers';
import { validateMicrosoftUser } from './../../../helpers/validation';

interface UserMessageInfo {
    customizedMessageBody: string | undefined;
    messageLanguage: string | undefined;
}

interface InvitationPayload {
    invitedUserEmailAddress: string | '';
    inviteRedirectUrl: string | '';
    invitedUserDisplayName: string | '';
    invitedUserMessageInfo: UserMessageInfo;
    sendInvitationMessage: boolean;
}

function checkUserExist(response: any) {
    let status = false;
    if (!response || !response.value || !response.value[0] || !response.value[0].id || response.value[0].id === '') {
        status = false;
    }
    else {
        status = true;
    }
    return status;
}

function buildInvitationRequestBody(userInfo: any, sendInvite: boolean) {
    const invitationRequestBody: InvitationPayload = {
        invitedUserEmailAddress: userInfo?.email?.trim(),
        inviteRedirectUrl: '',
        invitedUserDisplayName: `${userInfo?.firstName?.trim()} ${userInfo?.lastName?.trim()}`,
        invitedUserMessageInfo: {
            customizedMessageBody: i18n.t('graph_invitation_message'),
            messageLanguage: 'en-US',
        },
        sendInvitationMessage: sendInvite,
    };
    return invitationRequestBody;
}

export function* postAddAadUser(request: any): IterableIterator<any> {
    const retailUserResponse = {
        retailUser: request.payload,
        status: '',
        errorInfo: '',
    };
    let aadAddUserResponse: any = {};
    try {
        yield put(actionStatusStarted({ actionName: POST_ADD_AAD_USER }));
        //Check User Exist On AAD
        const checkAadUserResponse: any = yield call(GraphApiDal.checkAndGetAadUserId, retailUserResponse.retailUser.email ? retailUserResponse.retailUser.email : '');
        if (!checkUserExist(checkAadUserResponse) && !validateMicrosoftUser(retailUserResponse.retailUser.email)) {
            //Send Aad Invitation To User
            const invitationRequest = buildInvitationRequestBody(retailUserResponse.retailUser, true);
            aadAddUserResponse = yield call(GraphApiDal.sendAadInvitation, invitationRequest);
            if (aadAddUserResponse) {
                retailUserResponse.retailUser.aadUserId = aadAddUserResponse.invitedUser.id;
                retailUserResponse.retailUser.inviteRedeemUrl = aadAddUserResponse.inviteRedeemUrl;
            }
        }
        const userId = checkAadUserResponse?.value[0]?.id ? checkAadUserResponse?.value[0]?.id : aadAddUserResponse?.invitedUser?.id ? aadAddUserResponse?.invitedUser?.id : '';
        if (userId !== '') {
            retailUserResponse.retailUser.aadUserId = userId;
            aadAddUserResponse = yield call(GraphApiDal.addUserToGroup, userId);
            if (aadAddUserResponse && aadAddUserResponse.request && aadAddUserResponse.request.status && aadAddUserResponse.request.status !== 200) {
                retailUserResponse.status = 'error';
                retailUserResponse.errorInfo = i18n.t('graph_error_message');
            }
        }
        else {
            retailUserResponse.status = 'error';
            retailUserResponse.errorInfo = i18n.t('graph_error_message');
        }
        const postAddToDBRes: any = yield call(GraphApiDal.addUserToRoboDB, retailUserResponse.retailUser);
        if (!postAddToDBRes || !postAddToDBRes.status || postAddToDBRes.status.toLowerCase().trim() === 'email already exists') {
            retailUserResponse.status = 'error';
            retailUserResponse.errorInfo = postAddToDBRes.status.toLowerCase().trim() === 'email already exists' ? i18n.t('user_exists_on_robo_db') : retailUserResponse.errorInfo;
        }
        else {
            retailUserResponse.status = 'succeeded';
            retailUserResponse.errorInfo = '';
        }
        yield put(postAddAadUserSuccess(retailUserResponse));
        yield put(actionStatusSuccess({ actionName: POST_ADD_AAD_USER }));
    } catch (e) {
        retailUserResponse.status = 'error';
        retailUserResponse.errorInfo = e && e.message ? i18n.t(e?.message) : i18n.t('graph_error_message');
        yield put(postAddBulkAadUsersSuccess(retailUserResponse));
    }
}

export function* postAddBulkAadUsers(request: any): IterableIterator<any> {
    const bulkRetailUser: BulkRetailUserType = request.payload;
    let aadAddUserResponse: any = {};

    try {
        yield put(actionStatusStarted({ actionName: POST_ADD_BULK_AAD_USERS }));
        if (bulkRetailUser.action.toLowerCase() === 'status') {
            const checkAadUserResponse: any = yield call(GraphApiDal.checkAndGetAadUserId, bulkRetailUser.retailUser.email ? bulkRetailUser.retailUser.email : '');
            if (!validateMicrosoftUser(bulkRetailUser.retailUser.email)) {
                if (checkUserExist(checkAadUserResponse)) {
                    //Send Aad Invitation To User
                    const userId = checkAadUserResponse?.value[0]?.id;
                    aadAddUserResponse = yield call(GraphApiDal.checkUserInvitationStatus, userId);
                    if (aadAddUserResponse) {
                        if (aadAddUserResponse.externalUserState && aadAddUserResponse.externalUserState !== '' && aadAddUserResponse.externalUserState.toLowerCase() === 'accepted') {
                            bulkRetailUser.retailUser.aadUserId = userId;
                            
                            bulkRetailUser.status = 'success';
                            bulkRetailUser.errorInfo = i18n.t('graph_user_add_true_invite_true');
                        }
                        else {
                            bulkRetailUser.status = 'warning';
                            bulkRetailUser.errorInfo = i18n.t('graph_user_add_true_invite_false');
                        }
                    }
                    else {
                        bulkRetailUser.status = 'error';
                        bulkRetailUser.errorInfo = i18n.t('graph_error_message');
                    }
                }
                else {
                    bulkRetailUser.status = 'warning';
                    bulkRetailUser.errorInfo = i18n.t('graph_user_missing_on_aad');
                }
            }
            else {
                bulkRetailUser.status = 'success';
                bulkRetailUser.errorInfo = i18n.t('graph_user_invite_ms_account');
            }
        }
        else if (bulkRetailUser.action.toLowerCase() === 'delete') {
            const deleteUserPaylod = {
                requesterEmail: bulkRetailUser.retailUser.createdEmailId,
                userEmail: bulkRetailUser.retailUser.email,
            };
            const postdeleteFromDBRes: any = yield call(GraphApiDal.deleteUserFromDB, deleteUserPaylod);
            if (!postdeleteFromDBRes || postdeleteFromDBRes.status.toLowerCase().trim() !== 'succeeded') {
                bulkRetailUser.status = 'error';
                bulkRetailUser.errorInfo = i18n.t(postdeleteFromDBRes.status);
            }
            else {
                // Check if user exists in Group
                const checkAadUserResponse: any = yield call(GraphApiDal.checkAndGetAadUserId, bulkRetailUser.retailUser.email ? bulkRetailUser.retailUser.email : '');
                if (checkUserExist(checkAadUserResponse)) {
                    const userId = checkAadUserResponse?.value[0]?.id;
                    aadAddUserResponse = yield call(GraphApiDal.deleteUserFromGroup, userId);
                }
                bulkRetailUser.status = 'success';
            }
        }
        else {
            if (bulkRetailUser.action.toLowerCase() === 'add') {
                //Check User Exist On AAD
                const checkAadUserResponse: any = yield call(GraphApiDal.checkAndGetAadUserId, bulkRetailUser.retailUser.email ? bulkRetailUser.retailUser.email : '');
                if (!checkUserExist(checkAadUserResponse) && !validateMicrosoftUser(bulkRetailUser.retailUser.email)) {
                    //Send Aad Invitation To User
                    const invitationRequest = buildInvitationRequestBody(bulkRetailUser.retailUser, true);
                    aadAddUserResponse = yield call(GraphApiDal.sendAadInvitation, invitationRequest);
                    if (aadAddUserResponse) {
                        bulkRetailUser.retailUser.aadUserId = aadAddUserResponse.invitedUser.id;
                        bulkRetailUser.retailUser.inviteRedeemUrl = aadAddUserResponse.inviteRedeemUrl;
                    }
                }
                const userId = checkAadUserResponse?.value[0]?.id ? checkAadUserResponse?.value[0]?.id : aadAddUserResponse?.invitedUser?.id;
                if (userId !== undefined && userId !== '') {
                    bulkRetailUser.retailUser.aadUserId = userId;
                    aadAddUserResponse = yield call(GraphApiDal.addUserToGroup, userId);
                }
                else {
                    bulkRetailUser.status = 'error';
                    bulkRetailUser.errorInfo = i18n.t('graph_error_message');
                }
            }
            const postAddToDBRes: any = yield call(GraphApiDal.addUserToRoboDB, bulkRetailUser.retailUser);
            if (!postAddToDBRes || !postAddToDBRes?.status || postAddToDBRes.status.toLowerCase().trim() === 'email already exists') {
                bulkRetailUser.status = 'error';
                bulkRetailUser.errorInfo = i18n.t('user_exists_on_robo_db');
            }
            else {
                bulkRetailUser.status = 'success';
                bulkRetailUser.errorInfo = '';
            }
        }
        yield put(postAddBulkAadUsersSuccess(bulkRetailUser));
        yield put(actionStatusSuccess({ actionName: POST_ADD_BULK_AAD_USERS }));
    } catch (e) {
        bulkRetailUser.status = 'error';
        bulkRetailUser.errorInfo = e && e.message ? i18n.t(e?.message) : i18n.t('graph_error_message');
        yield put(postAddBulkAadUsersSuccess(bulkRetailUser));
    }
}

function* clearAadUserMessage(): IterableIterator<any> {
    try {
        yield put(actionStatusStarted({ actionName: CLEAR_AAD_USER_MESSAGE }));
        yield put(postAddAadUserSuccess(''));
        yield put(actionStatusSuccess({ actionName: CLEAR_AAD_USER_MESSAGE }));
    } catch (e) {
        actionStatusFailed({
            actionName: CLEAR_AAD_USER_MESSAGE,
            error: e,
        });
    }
}

function* clearBulkUserMessage(): IterableIterator<any> {
    try {
        yield put(actionStatusStarted({ actionName: CLEAR_BULK_USERS_MESSAGE }));
        yield put(postAddBulkAadUsersSuccess(''));
        yield put(actionStatusSuccess({ actionName: CLEAR_BULK_USERS_MESSAGE }));
    } catch (e) {
        actionStatusFailed({
            actionName: CLEAR_BULK_USERS_MESSAGE,
            error: e,
        });
    }
}

export default [
    takeEvery(`${POST_ADD_AAD_USER}`, postAddAadUser),
    takeEvery(`${POST_ADD_BULK_AAD_USERS}`, postAddBulkAadUsers),
    takeEvery(`${CLEAR_AAD_USER_MESSAGE}`, clearAadUserMessage),
    takeEvery(`${CLEAR_BULK_USERS_MESSAGE}`, clearBulkUserMessage),
];