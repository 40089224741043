import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Styles from './orderSummary.module.scss';
import history from 'src/history';
import {
    Progress,
    purchaseOrder,
    updateCreateOrderProgress,
    deviceShippingOfferSelector,
    isFullDeviceRepairSelector,
    accessoryShippingOfferSelector,
    reActivateOrderStatusSelector,
    purchaseOnDemandOrder,
    secondSRCreationSelector,
} from './../../store/orderCreation';
import { logPageAction } from 'src/lib/ApplicationInsights';
import { userAgentTierSelector } from './../../store/user/selector';

export default function OrderButton(props: any) {

    const dispatch = useDispatch();
    const userTier = useSelector(state => userAgentTierSelector(state));
    const isFullDeviceRepair: boolean = useSelector(state => isFullDeviceRepairSelector(state));
    const deviceShippingOffers: any = useSelector(state => deviceShippingOfferSelector(state));
    const accessoryshippingoffers: any = useSelector(state => accessoryShippingOfferSelector(state));
    const reActivateOrder = useSelector(state => reActivateOrderStatusSelector(state));
    const secondSRCreation = useSelector(state => secondSRCreationSelector(state));
    
    function onClickBack() {
        logPageAction('Go Back from Billing and Review: Back button clicked');
        if(secondSRCreation){
            dispatch(updateCreateOrderProgress(Progress.AddingShippingAddress));
        }
        else{
            dispatch(updateCreateOrderProgress(Progress.Registration));
        }
        
        history.push('/device/createOrder');
    }

    function onClickCreateOrder() {

        const repairType: string[] = [];

        if (deviceShippingOffers.length > 0){
            if (isFullDeviceRepair){
                repairType.push('Full device');
            }
            else if( deviceShippingOffers.length > 1){
                repairType.push('Multi part');
            }
            else {
                repairType.push('Single part');
            }
        }

        if (accessoryshippingoffers.length > 0) {
            if (accessoryshippingoffers.length > 1) {
                repairType.push('Multi Accessory');
            }
            else {
                repairType.push('Single Accessory');
            }
        }

        logPageAction('Submit order: Create Order button clicked',
            {
                tier: userTier,
                repairType: repairType.join(', '),
            });
        
        if(secondSRCreation){
            dispatch(purchaseOnDemandOrder({
                secondSRCreation,
                isOnDemandCheckOut:false,
                isOnSiteCheckOut: false,
                claimOrderType: 'onsite',
            }));
        }
        else if(!reActivateOrder){
            dispatch(purchaseOrder());
        }
        else {
            dispatch(purchaseOnDemandOrder({isOnDemandCheckOut:false}));
        }
        
        history.push('/device/orderConfirmation');
    }

    const { t } = useTranslation();

    return (
        <section className={clsx(Styles.container, props.className)}>
            <div className={Styles.row}>
                <div className={clsx(Styles.colOffSet8, Styles.col2)}>
                    <button
                        aria-label={t('back')}
                        className={clsx(Styles.button, Styles.buttonBack)}
                        onClick={onClickBack}>
                        {t('back')}
                    </button>
                </div>
                <div className={Styles.col2}>
                    <button
                        aria-label={t('createOrder')}
                        autoFocus={true}
                        className={clsx(Styles.button, Styles.buttonCreateOrder)}
                        onClick={onClickCreateOrder}>
                        {t('create_order')}
                    </button>
                </div>
            </div>
        </section>
    );
}
