export enum RepairOptions {
    hardwareRepair = 'SHWR',
    softwareRepair = 'SSWR',
    incompleteRepair = 'IRVF',
    unsuccessfulRepair = 'URAE',
    orderCancel = 'OCNC',
}

export const TravelOutcomeData = [
    { key: RepairOptions.hardwareRepair, text: 'hardware_repair_dropdown' },
    { key: RepairOptions.incompleteRepair, text: 'incomplete_repair_dropdown' },
    { key: RepairOptions.unsuccessfulRepair, text: 'unsuccessful_repair_dropdown' },
    { key: RepairOptions.orderCancel, text: 'order_cancel_dropdown' },
];