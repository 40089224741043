import React from 'react';
import { Modal } from 'office-ui-fabric-react/lib/Modal';

interface IImgModalProps {
    thumbnailSrc : string;
    imageSrc : string;
    alt?: string;
    className?: string;

}
const ImgModal = ({ alt, className, imageSrc , thumbnailSrc }: IImgModalProps) => {
    const [showModal, setShowModal] = React.useState(false);
    const close = () => { setShowModal(false); };
    const open = () => setShowModal(true);
    return (
        <>
            {showModal &&
                <Modal
                    isOpen={showModal}
                    onDismiss={close}
                    isBlocking={false}
                    titleAriaId="attachments"
                >
                    <img id="attachments" src={imageSrc } alt={alt} className={className} style={{ width: '100%', maxHeight: 'unset', height: '100%' }} />
                </Modal>}

            <img src={thumbnailSrc } onClick={open} alt={alt} className={className} />
        </>
    );
};

export default ImgModal;