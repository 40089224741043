import clsx from 'clsx';
import * as React from 'react';
import { validateSerialNumber } from './../../helpers/validation';
import { logPageAction } from './../../lib/ApplicationInsights';
import { ITextFieldStyles, Spinner, SpinnerSize, TextField } from 'office-ui-fabric-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AnnounceMessage } from 'react-screen-reader-announce';
import { resetStoreOnSearch } from './../../store/action';
import { actionStatusReset, LoadingState, actionStatusSelector } from './../../store/actionStatus';
import { getDeviceInfoData, GET_DEVICE_INFO_DATA } from './../../store/deviceInfo/deviceInfo';
import { userAgentTierSelector, userInfoSelector } from './../../store/user';
import history from 'src/history';
import Styles from './serviceRequestAndDeviceLookup.module.scss';
import auditLogEvents from './../../constants/auditLogEvents';

interface DeviceWarrantyInputPropsType {
    onError?: (message: string) => void;
}

const textFieldStyles: Partial<ITextFieldStyles> = {
    root: {
        marginLeft: 22,
        width: 160,
    },
};

export default function DeviceWarrantyInput(props: DeviceWarrantyInputPropsType) {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [serialNumber, setSerialNumber] = React.useState<string | undefined>(undefined);
    const agentTier = useSelector(state => userAgentTierSelector(state));
    const deviceInfoDataStatus = useSelector(state => actionStatusSelector(state)(GET_DEVICE_INFO_DATA));
    const userInfo = useSelector(state => userInfoSelector(state));

    const loadingStarted = deviceInfoDataStatus.status === LoadingState.started;
    const loadingComplete = deviceInfoDataStatus.status === LoadingState.success || deviceInfoDataStatus.status === LoadingState.rejected;
    const isDisabled = !serialNumber || loadingStarted;

    const buttonClassName = clsx(
        Styles.button,
        Styles.commonFocus,
        { [Styles.disabled]: isDisabled }
    );

    React.useEffect(() => {
        if (deviceInfoDataStatus.status === LoadingState.success) {
            history.push('/device/warrantyDetail');
        }

    }, [deviceInfoDataStatus.status]);

    function onSerialNumberChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void {
        setSerialNumber(newValue);
    }

    function onClick(event: any) {

        event.preventDefault();
        logPageAction('Continue to warranty details page: Select button clicked');

        dispatch(actionStatusReset({ actionName: GET_DEVICE_INFO_DATA }));

        if (!serialNumber || !validateSerialNumber(serialNumber)) {
            if (props.onError) {
                props.onError(t('please_provide_valid_serial_number'));
            }
            return;
        }

        logPageAction(auditLogEvents.WarrantyLookupEvent, { serialNumber, userId: userInfo.retailUserId });

        if (props.onError) {
            props.onError('');
        }
        dispatch(resetStoreOnSearch());
        dispatch(
            getDeviceInfoData({
                serialNumber,
                agentTier,
                isWarrantyOnly: true,
            })
        );
    }

    if (loadingStarted) {
        AnnounceMessage(t('loading'));
    } else if (loadingComplete) {
        // Only say loading complete on success/failure, else React will go in infinite loop during change user tier
        AnnounceMessage(t('loading_complete'));
    }

    return (
        <form className={Styles.inputsSection}>
            <TextField
                id="txt-serial-number"
                autoComplete="on"
                name="SerialNumber"
                ariaLabel={t('serial_number')}
                disabled={deviceInfoDataStatus === LoadingState.started}
                label={t('serial_number')}
                required={true}
                onChange={onSerialNumberChange}
                styles={textFieldStyles}
            />
            <button
                id="btn-select"
                type="submit"
                className={buttonClassName}
                aria-label={t('select')}
                onClick={onClick}
                disabled={isDisabled}
            >
                {t('select')}
            </button>
            {
                loadingStarted && <Spinner
                    className={Styles.spinnerStyle}
                    size={SpinnerSize.medium}
                />
            }
        </form >
    );
}