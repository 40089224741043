export default {
    onDemandEnableCountries: ['US', 'CA', 'UK', 'AT', 'BE', 'BG', 'CH', 'CY', 'CZ', 'DE', 'DK',
        'ES', 'EE', 'FI', 'FR', 'GB', 'GR', 'HR', 'HU', 'IE', 'IS', 'IT', 'LI', 'LT', 'LU', 'LV',
        'MT', 'NL', 'NO', 'PL', 'PT', 'RO', 'SK', 'SI', 'SE', 'CN'],
    onDemandEnableTiers: ['25', '12'],
    onCruEnableCountries: ['US', 'CA', 'CN', 'GB', 'FR', 'DE', 'AU', 'JP', 'NZ', 'HK', 'MX', 'IE', 'AT', 'BE',
        'NL', 'LU', 'CH', 'BG', 'HR', 'CZ', 'EE', 'GR', 'HU', 'LV', 'LT', 'PL', 'RO', 'SK', 'SI', 'IT', 'PT', 'ES',
        'DK', 'FI', 'NO', 'SE', 'BH', 'KW', 'OM', 'QA', 'SA', 'AE', 'ZA', 'MY', 'SG', 'TW', 'JP', 'KR', 'TH', 'IN',
        'EG', 'CY', 'MT', 'PH', 'VN'],
    onCruEnableTiers: ['13', '14'],
};